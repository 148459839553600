<template>
  <div v-if="loading" class="text-center">
    <v-progress-circular
        indeterminate
        color="primary"
    ></v-progress-circular>
  </div>
  <div v-else-if="error" class="text-center">
    {{ error }}
  </div>
  <div v-else>
    <v-alert v-if="currentBenchmarksCount > 0" :value="true" type="warning">
      <span>
        {{ $t('interface.benchmarks_not_supported') }}
      </span>
    </v-alert>

    <v-data-table
      :headers="headers"
      :items="Object.values(data)"
      class="elevation-1"
      :hide-default-footer="data.length <= 10"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td width="40%">
            <span v-if="item.group === null"> 
              <i>Niet gegroepeerd</i>
            </span>
            <span v-else>
              {{ item.group }}
            </span>
          </td>
          <template v-if="item.finished >= $store.getters.reportSettings.min_results">
            <td width="20%" class="text-center">
              {{ item.total }}
            </td>
            <td width="20%" class="text-center">
              {{ item.finished }}
            </td>
            <td width="20%" class="text-center">
              {{ item.percent | dutch_number }}%
            </td>
          </template>
          <template v-else>
            <td width="20%" class="text-center"><response-too-low-tooltip/></td>
            <td width="20%" class="text-center"><response-too-low-tooltip/></td>
            <td width="20%" class="text-center"><response-too-low-tooltip/></td>
          </template>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {NolostInsights} from '@/mixins/NolostInsights'
import ResponseTooLowTooltip from '@/components/reportPageContent/ResponseTooLowTooltip.vue'

export default {
  name: "ProjectResponse",
  mixins: [NolostInsights],
  props: ['content'],
  components: {
    ResponseTooLowTooltip
  },
  data() {
    return {
      error: undefined,
      loading: true,
      headers: [
        {text: 'Groep', value: 'group', sortable: true, align: 'left'},
        {text: 'Aantal', value: 'finished', sortable: true, align: 'center'},
        {text: 'Ingevuld', value: 'finished', sortable: true, align: 'center'},
        {text: 'Percentage', value: 'percent', sortable: true, align: 'center'},
      ],
      data: []
    }
  },
  computed: {
    currentComparesCount () { return this.$store.state.currentCompares },
    currentFiltersCount () { return this.$store.state.currentFilters },
    currentBenchmarksCount () { return this.$store.state.currentBenchmarks.length || 0 },
    currentParticipantCount () { return this.questiondata.total_count }
  },
  methods: {
    getData() {
      this.loading = true;
      this.axios.get(process.env.VUE_APP_API_URL + '/insights/response/?grouping=' + this.content.filter)
        .then(resp => {
          let groups = resp.data.data
          groups.push(resp.data.total)
          this.data = groups
          this.loading = false;
        })
        .catch(err => {
          this.error = err
          this.questions = []
          this.loading = false;
        })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

