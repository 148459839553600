<template>
  <div v-if="negative" class="progress-bar">
    {{ item.perc_ }}
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div 
          :style="`width: ${item.perc_neg * 100}%`" 
          v-bind="attrs"
          v-on="on"
          class="progress-bar-item error">
        </div>
      </template>
      <span>
        {{ (item.perc_neg * 100).toFixed(1) | dutch_number }}% 
        {{ $t('antwoorden.progress-bar.negative') }}
      </span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div 
          :style="`width: ${(item.perc_neutral * 100)}%`" 
          v-bind="attrs"
          v-on="on"
          class="progress-bar-item gray">
        </div>
      </template>
      <span>
        {{ (item.perc_neutral * 100).toFixed(1) | dutch_number }}%
        {{ $t('antwoorden.progress-bar.neutral') }}
      </span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div 
          :style="`width: ${item.perc_pos * 100}%`" 
          v-bind="attrs"
          v-on="on"
          class="progress-bar-item positive">
        </div>
      </template>
      <span>
        {{ (item.perc_pos * 100).toFixed(1) | dutch_number }}%
        {{ $t('antwoorden.progress-bar.positive') }}
      </span>
    </v-tooltip>
  </div>

  <div v-else class="progress-bar">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div 
          :style="`width: ${item.perc_pos * 100}%`" 
          v-bind="attrs"
          v-on="on"
          class="progress-bar-item positive">
        </div>
      </template>
      <span>
        {{ (item.perc_pos * 100).toFixed(1) | dutch_number }}%
        {{ $t('antwoorden.progress-bar.positive') }}
      </span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div 
          :style="`width: ${(item.perc_neutral * 100)}%`" 
          v-bind="attrs"
          v-on="on"
          class="progress-bar-item gray">
        </div>
      </template>
      <span>
        {{ (item.perc_neutral * 100).toFixed(1) | dutch_number }}%
        {{ $t('antwoorden.progress-bar.neutral') }}
      </span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div 
          :style="`width: ${item.perc_neg * 100}%`" 
          v-bind="attrs"
          v-on="on"
          class="progress-bar-item error">
        </div>
      </template>
      <span>
        {{ (item.perc_neg * 100).toFixed(1) | dutch_number }}% 
        {{ $t('antwoorden.progress-bar.negative') }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>

export default {
  name: "Progressbar",
  props: ['item', 'negative'],
  mixins: [],
}    
</script>
