<template>

    <v-list three-line>
      <template v-for="(item, index) in content.items">
        <v-list-item
          :key="index"
          avatar
        >
          <v-list-item-avatar>
            {{ index }}
          </v-list-item-avatar>

          <v-list-item-content>
            {{ item.text }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

</template>

<script>
  export default {
    name: "SubjectScore",
    props: ['content'],
  }
</script>

