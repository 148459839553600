import chroma from 'chroma-js'
import { Trans } from '@/plugins/Translation'

export const NolostInsights = {
  data () {
    return {

    }
  },
  methods: {
    getPalette(content) {
      let palette = ''
      if (typeof content.palette !== 'undefined') {
        palette = content.palette
      }
      return palette
    },
    getLabels (qtype) {
      let answers = ['ERROR: questionObject.qtype unknown']
      switch (qtype) {
        case '5-point-likert-agree':
        case '51-point-likert-agree':
          answers = ['antwoorden.likert.agree1', 'antwoorden.likert.agree2', 'antwoorden.likert.agree4', 'antwoorden.likert.agree6', 'antwoorden.likert.agree7']
          break

        case '7-point-likert-agree':
        case '71-point-likert-agree':
          answers = ['antwoorden.likert.agree1', 'antwoorden.likert.agree2', 'antwoorden.likert.agree3', 'antwoorden.likert.agree4', 'antwoorden.likert.agree5', 'antwoorden.likert.agree6', 'antwoorden.likert.agree7']
          break

        case '5-point-likert-fast-slow':
        case '51-point-likert-fast-slow':
          answers = ['antwoorden.likert.fast1', 'antwoorden.likert.fast2', 'antwoorden.likert.fast4', 'antwoorden.likert.fast6', 'antwoorden.likert.fast7']
          break

        case '7-point-likert-fast-slow':
        case '71-point-likert-fast-slow':
          answers = ['antwoorden.likert.fast1', 'antwoorden.likert.fast2', 'antwoorden.likert.fast3', 'antwoorden.likert.fast4', 'antwoorden.likert.fast5', 'antwoorden.likert.fast6', 'antwoorden.likert.fast7']
          break

        case '5-point-likert-frequency':
        case '51-point-likert-frequency':
          answers = ['antwoorden.likert.freq1', 'antwoorden.likert.freq2', 'antwoorden.likert.freq4', 'antwoorden.likert.freq6', 'antwoorden.likert.freq7']
          break

        case '7-point-likert-frequency':
        case '71-point-likert-frequency':
          answers = ['antwoorden.likert.freq1', 'antwoorden.likert.freq2', 'antwoorden.likert.freq3', 'antwoorden.likert.freq4', 'antwoorden.likert.freq5', 'antwoorden.likert.freq6', 'antwoorden.likert.freq7']
          break

        case '5-point-likert-occurance':
        case '51-point-likert-occurance':
          answers = ['antwoorden.likert.occ1', 'antwoorden.likert.occ2', 'antwoorden.likert.occ4', 'antwoorden.likert.occ6', 'antwoorden.likert.occ7']
          break

        case '7-point-likert-occurance':
        case '71-point-likert-occurance':
          answers = ['antwoorden.likert.occ1', 'antwoorden.likert.occ2', 'antwoorden.likert.occ3', 'antwoorden.likert.occ4', 'antwoorden.likert.occ5', 'antwoorden.likert.occ6', 'antwoorden.likert.occ7']
          break

        case '5-point-likert-good-bad':
        case '51-point-likert-good-bad':
          answers = ['antwoorden.likert.good1', 'antwoorden.likert.good2', 'antwoorden.likert.good4', 'antwoorden.likert.good6', 'antwoorden.likert.good7']
          break

        case '7-point-likert-good-bad':
        case '71-point-likert-good-bad':
          answers = ['antwoorden.likert.good1', 'antwoorden.likert.good2', 'antwoorden.likert.good3', 'antwoorden.likert.good4', 'antwoorden.likert.good5', 'antwoorden.likert.good6', 'antwoorden.likert.good7']
          break

        case '5-point-likert-high-low':
        case '51-point-likert-high-low':
          answers = ['antwoorden.likert.high1', 'antwoorden.likert.high2', 'antwoorden.likert.high4', 'antwoorden.likert.high6', 'antwoorden.likert.high7']
          break

        case '7-point-likert-high-low':
        case '71-point-likert-high-low':
          answers = ['antwoorden.likert.high1', 'antwoorden.likert.high2', 'antwoorden.likert.high3', 'antwoorden.likert.high4', 'antwoorden.likert.high5', 'antwoorden.likert.high6', 'antwoorden.likert.high7']
          break

        case '5-point-likert-important':
        case '51-point-likert-important':
          answers = ['antwoorden.likert.important1', 'antwoorden.likert.important2', 'antwoorden.likert.important4', 'antwoorden.likert.important6', 'antwoorden.likert.important7']
          break

        case '7-point-likert-important':
        case '71-point-likert-important':
          answers = ['antwoorden.likert.important1', 'antwoorden.likert.important2', 'antwoorden.likert.important3', 'antwoorden.likert.important4', 'antwoorden.likert.important5', 'antwoorden.likert.important6', 'antwoorden.likert.important7']
          break

        case '5-point-likert-long-short':
        case '51-point-likert-long-short':
          answers = ['antwoorden.likert.long1', 'antwoorden.likert.long2', 'antwoorden.likert.long4', 'antwoorden.likert.long6', 'antwoorden.likert.long7']
          break

        case '7-point-likert-long-short':
        case '71-point-likert-long-short':
          answers = ['antwoorden.likert.long1', 'antwoorden.likert.long2', 'antwoorden.likert.long3', 'antwoorden.likert.long4', 'antwoorden.likert.long5', 'antwoorden.likert.long6', 'antwoorden.likert.long7']
          break

        case 'grade-1-10-open':
          answers = ['antwoorden.cijfer.1', 'antwoorden.cijfer.2', 'antwoorden.cijfer.3', 'antwoorden.cijfer.4', 'antwoorden.cijfer.5', 'antwoorden.cijfer.6', 'antwoorden.cijfer.7', 'antwoorden.cijfer.8', 'antwoorden.cijfer.9', 'antwoorden.cijfer.10']
          break
      }

      if (qtype.indexOf('1-p') > 0) { // a .1 likert variant, add extra option
        answers.push('antwoorden.likert.nvt')
      }
      return answers
    },
    getColourClass(index, question, colorPalette = '') {

      let classPositive = "positive-light"
      let classNeutral = "neutral-light"
      let classNegative = "negative-light"

      if (colorPalette == "neutral") {
        classPositive = "neutral-outside"
        classNeutral = "neutral-center"
        classNegative = "neutral-outside"
      }

      if (question.is_negative && question.qtype.startsWith("7")) {
        index = 6 - index
      }
      else if (question.is_negative && question.qtype.startsWith("5")) {
        index = 4 - index
      }
      else if (question.is_negative && question.qtype.startsWith("grade-1-10")) {
        index = 9 - index
      }

      let classes = []

      if (question.qtype.startsWith("71")) {
        classes.push("eight")
      }
      else if (question.qtype.startsWith("7")) {
        classes.push("seven")
      }
      else if (question.qtype.startsWith("51")) {
        classes.push("six")
      }
      else if (question.qtype.startsWith("5")) {
        classes.push("five")
      }
      else if (question.qtype.startsWith("grade-1-10")) {
        classes.push("ten")
      }

      if (question.qtype.startsWith("7-point-likert-frequency") || question.qtype.startsWith("71-point-likert-frequency")) {
        switch (index) {
          case 0:
          case 1:
            classes.push(classPositive)
            break
          case 2:
            classes.push(classNeutral)
            break
          case 3:
          case 4:
          case 5:
          case 6:
            classes.push(classNegative)
            break
        }
      }
      else if (question.qtype.startsWith("7")) {
        switch (index) {
          case 0:
          case 1:
          case 2:
            classes.push(classPositive)
            break
          case 3:
            classes.push(classNeutral)
            break
          case 4:
          case 5:
          case 6:
            classes.push(classNegative)
            break
        }
      }
      else if (question.qtype.startsWith("5")) {
        switch (index) {
          case 0:
          case 1:
            classes.push(classPositive)
            break

          case 2:
            classes.push(classNeutral)
            break
          case 3:
          case 4:
            classes.push(classNegative)
            break
        }
      }
      else if (question.qtype.startsWith("grade-1-10")) {
        switch (index) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
            classes.push(classNegative)
            break
          case 5:
          case 6:
            classes.push(classNeutral)
            break
          case 7:
          case 8:
          case 9:
            classes.push(classPositive)
            break
        }
      }
      else if (question.qtype.startsWith("yes-no")) {
        if (!question.is_negative) {
          switch (index) {
            case "yes":
              classes.push(classPositive)
              break
            case "no":
              classes.push(classNegative)
              break
          }
        }
        else {
          switch (index) {
            case "yes":
              classes.push(classNegative)
              break
            case "no":
              classes.push(classPositive)
              break
          }
        }

      }
      return classes
    },
    getIndexedCounts (questiondata) {
      questiondata.counts_i = []
      let length = 0
      if (questiondata.question.qtype.startsWith(51)) {
        length = 6
      }
      else if (questiondata.question.qtype.startsWith(5)) {
        length = 5
      }
      else if (questiondata.question.qtype.startsWith(71)) {
        length = 8
      }
      else if (questiondata.question.qtype.startsWith(7)) {
        length = 7
      }
      else if (questiondata.question.qtype.startsWith('grade-1-10-open')) {
        length = 10
      }

      for (let i = 1; i <= length; i++) {
        questiondata.counts_i[i] = 0
      }
      for (let i = 0; i < questiondata.counts.length; i++) {
        questiondata.counts_i[questiondata.counts[i].value_as_int] = questiondata.counts[i].count
      }
      return questiondata
    },
    getPositive(questiondata, percent = false) {
      if (questiondata.question.qtype === "yes-no") {
        return questiondata.answers.yes
      }

      if (percent) {
        return (questiondata.perc_pos * 100).toFixed(1)
      }
      else {
        return questiondata.count_pos
      }
    },
    getNegative(questiondata, percent = false) {
      if (questiondata.question.qtype === "yes-no") {
        return questiondata.answers.no
      }

      if (percent) {
        return (questiondata.perc_neg * 100).toFixed(1)
      }
      else {
        return questiondata.count_neg
      }
    },
    getNeutral(questiondata, percent = false) {
      if (percent) {
        return (questiondata.perc_neutral * 100).toFixed(1)
      }
      else {
        return questiondata.count_neutral
      }
    },
    getNolostColor(index, light = false) {
      const colors = ["#009BD9", "#0F265C", "#EF7D00", "#9460a4", "#00A583", "#E4003A", "#83929B", ]
      const lightColors = ["#BFE6F5", "#C3C9D6", "#FBDEBF", "#E4D7E8", "#BFE8E0", "#F8BFCE", "#E0E4E6", ]
      if (light) {
        return lightColors[index]
      }
      return colors[index]
    },
    getRedGreenColor(value, palette = '') {
      if (palette == 'neutral') {
        const colorScale = chroma.scale(['#E7E9EE', '#0F265C']).mode('lrgb');
        return colorScale(value).hex()
      }
      else if (palette == 'custom') {
        const colorScale = chroma.scale(['#e4003a', '#f1a135', '#00ac85'])
            .domain([0,0.75,1]).classes(8);
        return colorScale(value).hex()
      }
      else {
        let hue = (((value-.5) * 2) * 165);
        hue = Math.max(hue, 0).toString(10)
        return ["hsl(", hue, ",100%,38%)"].join("");
      }
    },
    templateQuestion(questionText, reportSettings={}) {
      let placeholders = reportSettings.placeholders

      // Get the current language and overwrite any placeholders that might be 
      placeholders = {...placeholders, ...reportSettings[`placeholders_${Trans.currentLanguage}`]}

      // This templating function allows us to use {template} tags
      // in questions and have them replaced in an insight report.
      // It comes with a couple of defaults to support backwards compatability
      let defaults = {
        "project_company_name": "de organisatie",
        "url_metadata_term_leidinggevende": "leidinggevende",
        ...placeholders, // Overwrite the defaults with any given data
      }
      
      // For each tag, try to find and replace it:
      for (const tag in defaults) {
        questionText = questionText.replace(`{${tag}}`, defaults[tag])
      }

      // We then return the question text with everything replaced
      return questionText
    }
  },
  watch: {
    '$route': 'getData',
    '$i18n.locale': 'getData',
    '$store.state.filters': 'getData',
  }
}
