<template>
  <div class="component-count-per-score" >
    <div v-if="loading" class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-card v-for="(questiondata, index) in questions" :key="index" class="mb-3">
        <v-card-title class="accent white--text">
          {{ templateQuestion(questiondata.question.question || questiondata.question.name, $store.getters.reportSettings) }}
          <negative-question-tooltip :questiondata="questiondata.question" v-if="questiondata.question.is_negative" />
          <v-spacer />
          <span class="text-body-2" v-if="questiondata.total_count > $store.getters.reportSettings.min_results">{{ $tc('interface.participant_count') }}: {{ questiondata.total_count }}</span>
        </v-card-title>
        <v-card-text class="pa-3">
          <v-container v-if="questiondata.total_count >= $store.getters.reportSettings.min_results">
            <v-row align="center">
              <v-col cols="11" class="text-center font-weight-light">
                <table align="center" style="table-layout: fixed; width: 100%;">
                  <tr>
                    <th v-for="(label, i) in getLabels(questiondata.question.qtype)"
                        :class="getColourClass(i, questiondata.question, colorPalette)"
                        :key="i"
                        class="pa-2 score-label"
                    >
                      {{ $t(label) }}
                    </th>
                  </tr>
                  <tr>
                    <td v-for="(label, i) in getLabels(questiondata.question.qtype)"
                        :class="getColourClass(i, questiondata.question, colorPalette)"
                        :key="i"
                        class="pa-4"
                    >
                    <span v-if="show_counts">{{ questiondata.counts_i[i+1] }}</span>
                    <span v-else>{{ (questiondata.counts_i[i+1] / questiondata.total_count * 100).toFixed(1) | dutch_number }}%</span>
                    </td>
                  </tr>
                </table>
              </v-col>
              <v-col col="1" class="text-center font-weight-light display-2 grade">
                {{ questiondata.score.toFixed(1) | dutch_number }}
              </v-col>
            </v-row>
            <template v-if="!content.hidepercentage">
              <v-row v-if="!negative_display(questiondata.question)" align-center>
                <v-col cols="1" class="text-center headline score-percentage" :class="positiveClass.concat('--text')">
                  {{ Math.round(getPositive(questiondata, true)) }}%
                </v-col>
                <v-col cols="5">
                  <v-progress-linear
                    :color="positiveClass"
                    rounded
                    height="10"
                    :value="Math.round(getPositive(questiondata, true))"
                  ></v-progress-linear>
                </v-col>
                <v-col cols="1" class="text-center headline score-percentage" :class="negativeClass.concat('--text')">
                  {{ Math.round(getNegative(questiondata, true)) }}%
                </v-col>
                <v-col cols="5">
                  <v-progress-linear
                    :color="negativeClass"
                    rounded
                    height="10"
                    :value="Math.round(getNegative(questiondata, true))"
                  ></v-progress-linear>
                </v-col>
              </v-row>

              <v-row v-else align-center>
                <v-col cols="1" class="text-center headline score-percentage" :class="negativeClass.concat('--text')">
                  {{ Math.round(getNegative(questiondata, true)) }}%
                </v-col>
                <v-col cols="5">
                  <v-progress-linear
                    :color="negativeClass"
                    rounded
                    height="10"
                    :value="Math.round(getNegative(questiondata, true))"
                  ></v-progress-linear>
                </v-col>
                <v-col cols="1" class="text-center headline score-percentage" :class="positiveClass.concat('--text')">
                  {{ Math.round(getPositive(questiondata, true)) }}%
                </v-col>
                <v-col cols="5">
                  <v-progress-linear
                    :color="positiveClass"
                    rounded
                    height="10"
                    :value="Math.round(getPositive(questiondata, true))"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </template>
          </v-container>
          <v-container v-else>
            <v-alert :value="true" type="warning">
              {{ $t('interface.onvoldoende_respons') }}
            </v-alert>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
  import { NolostInsights } from '@/mixins/NolostInsights'
  import NegativeQuestionTooltip from '@/components/reportPageContent/NegativeQuestionTooltip.vue'
  // import QuestionBenchmark from '@/components/reportPageContent/QuestionBenchmark.vue'

  export default {
    name: "CountPerScore",
    mixins: [NolostInsights],
    props: ['content'],
    components: {
      NegativeQuestionTooltip
    },
    data () {
      return {
        questions: false,
        labels: false,
        response: "-",
        loading: true,
        total_count: 0,
        colorPalette: this.getPalette(this.content),
        positiveClass: this.getPalette(this.content) == 'neutral' ? 'accent' : 'positive',
        negativeClass: this.getPalette(this.content) == 'neutral' ? 'accent' : 'error',
        show_counts: this.content.show_counts || false
      }
    },
    computed: {
      currentComparesCount () { return this.$store.state.currentCompares },
      currentFiltersCount () { return this.$store.state.currentFilters.length || 0 },
      currentBenchmarksCount () { return this.$store.state.currentBenchmarks.length || 0 },
    },
    methods: {
      negative_display (question) {
        if (question.qtype.startsWith("grade-1-10")) {
          return !question.is_negative
        }
        else
          return question.is_negative
      },
      getData () {
        this.loading = true;
        this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' +
                this.content.questions.join(","))
          .then(resp => {
            this.questions = resp.data.map(item => {
              this.total_count = item.total_count
              item.index = this.content.questions.findIndex(id => item.question.id === id) // find the index
              item = this.getIndexedCounts(item) // Get the index counts
              item.labels = this.getLabels(item.question.qtype) // Get the labels for each index
              return item 
            })
            this.questions.sort((a, b) => a.index - b.index) // Sort on index
            this.loading = false
          })
          .catch(err => {
            this.error = err
            this.loading = false
          })
      }
    },
    mounted() {
      this.getData()
    },
    watch: {
      'currentFiltersCount': 'getData',
    }
  }
</script>

<style scoped>
  @media print {
    div.component-count-per-score {
      break-inside: avoid !important;
      page-break-inside: avoid !important;
    }
    div.component-count-per-score div.grade {
      font-size: 1.5rem !important;
    }
    div.component-count-per-score .score-percentage {
      font-size: 1rem !important;
    }
    div.component-count-per-score .score-label {
      font-size: .75rem !important;
    }
    div.component-count-per-score blockquote {
      display: none;
    }
  }
  .eight {
    width: 12.5%;
  }
  .seven {
    width: 14.285%;
  }
  .six {
    width: 16.666%;
  }
  .five {
    width: 20%;
  }
  .ten {
    width: 10%;
  }

</style>
