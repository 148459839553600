<template>

    <v-data-table
            v-if="responsedata.data"
            :headers="headers"
            :items="responsedata.data"
            class="elevation-1"
            :hide-default-footer="responsedata.data.length <= 10"
    >
        <template slot="items" slot-scope="props">
          <tr>
            <td>{{ props.item.group }}</td>
            <template v-if="props.item.finished >= $store.getters.reportSettings.min_results">
              <td class="text-center">{{ props.item.finished }}</td>
            </template>
            <template v-else>
              <td class="text-center"><response-too-low-tooltip /></td>
            </template>
          </tr>
        </template>

        <template slot="body.append" v-if="responsedata.total">
          <tr>
            <td class="font-weight-medium">{{ responsedata.total.group }} </td>
            <td class="text-center font-weight-medium">{{ responsedata.total.finished }}</td>
          </tr>
        </template>
  </v-data-table>

</template>

<script>
  import { NolostInsights } from '@/mixins/NolostInsights'
  import ResponseTooLowTooltip from '@/components/reportPageContent/ResponseTooLowTooltip.vue'

  export default {
    name: "ProjectResponseSimple",
    mixins: [NolostInsights],
    props: ['content'],
    components: {
      ResponseTooLowTooltip
    },
    data () {
      return {
        headers: [
          {text: 'Groep', value: 'group', sortable: true, align: 'left'},
          {text: 'Ingevuld', value: 'finished', sortable: true, align: 'center'},
        ],
        responsedata: []
      }
    },
    methods: {
      getData () {
        this.loading = true;

        this.axios.get(process.env.VUE_APP_API_URL + '/insights/response/?grouping=' + this.content.filter)
          .then(resp => {
            this.responsedata = resp.data;
            this.loading = false;
          })
          .catch(err => {
            this.error = err
            this.questions = []
            this.loading = false;
          })
      }
    },
    mounted() {
      this.getData()
    }
  }
</script>

