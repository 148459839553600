<template>
  <span>
    <!-- If the response is to small -->
    <response-too-low-tooltip v-if="item.benchmarks[benchmark.id] && item.benchmarks[benchmark.id].total_count < $store.getters.reportSettings.min_results"/>
    
    <!-- Or the score is set to zero (for incomplete project benchmarks that have a 0.0 in their response) -->
    <insufficient-data-tooltip v-else-if="item.benchmarks[benchmark.id] && item.benchmarks[benchmark.id].score === 0.0"/>
    
    <!-- If there is a score set and we want to show just the score. 0 - 10 -->
    <v-chip v-else-if="item.benchmarks[benchmark.id] && item.benchmarks[benchmark.id].score !== false && show_score"
      :class="highlight(item.benchmarks[this.benchmark.id], item)"
      class="score">
      {{ parseFloat(item.benchmarks[benchmark.id].score).toFixed() }}
    </v-chip>

    <!-- If we don't want to show the score and thus the percentage, but show_negative is passed 0 - 100% -->
    <v-chip v-else-if="item.benchmarks[benchmark.id] && item.benchmarks[benchmark.id].score !== false && !show_score && show_negative"
      :class="highlight(item.benchmarks[this.benchmark.id], item)"
      class="score">
      {{ parseFloat(getBenchmarkNegative(item, benchmark.id)).toFixed(0) }}%
    </v-chip>

    <!-- If we don't want to show the percentage sign after the score-->
    <v-chip v-else-if="item.benchmarks[benchmark.id] && item.benchmarks[benchmark.id].score !== false && !show_score"
      :class="highlight(item.benchmarks[this.benchmark.id], item)"
      class="score">
      {{ parseFloat(getBenchmarkPositive(item, benchmark.id)).toFixed(0) }}%
    </v-chip>

  </span>
</template>

<script>
import { NolostInsights } from '@/mixins/NolostInsights';
import InsufficientDataTooltip from '@/components/reportPageContent/InsufficientDataTooltip.vue'
import ResponseTooLowTooltip from '@/components/reportPageContent/ResponseTooLowTooltip.vue'

export default {
  name: "QuestionBenchmarkProject",
  mixins: [NolostInsights],
  props: [
    'item',
    'benchmark',
    'invert_colors',
    'show_score',
    'show_negative'
  ],
  components: {
    InsufficientDataTooltip,
    ResponseTooLowTooltip,
  },
  methods: {
    highlight(benchmarkItem, item) {
      // console.log(benchmarkItem, item)
      
      // // When showing a score, we need different cuttoffs and calculations
      if (this.show_score) {
        let cutoffLow = 0.5 // item.std_dev * 0.125
        let cutoffHigh = 1 // item.std_dev * 0.25
        
        // If the question is asked negatively or invert_colors is set on the table-distribution component
        if (this.show_negative || this.invert_colors) {
          if (benchmarkItem.score - item.score <= -cutoffHigh) return 'highlight_neg'
          if (benchmarkItem.score - item.score <= -cutoffLow) return 'highlight_med_neg'
          if (benchmarkItem.score - item.score >= cutoffHigh) return 'highlight_pos'
          if (benchmarkItem.score - item.score >= cutoffLow) return 'highlight_med_pos'
        } else {
          // Otherwise, highlight normally
          if (benchmarkItem.score - item.score <= -cutoffHigh) return 'highlight_pos'
          if (benchmarkItem.score - item.score <= -cutoffLow) return 'highlight_med_pos'
          if (benchmarkItem.score - item.score >= cutoffHigh) return 'highlight_neg'
          if (benchmarkItem.score - item.score >= cutoffLow) return 'highlight_med_neg'
        }
      // // Otherwise, we use the percentages
      } else {
        // console.log(benchmarkItem.perc_pos, item.perc_pos)
        let cutoffLow = 0.05 // item.std_dev * 0.125
        let cutoffHigh = 0.10 // item.std_dev * 0.25

        // If the question is asked negatively or invert_colors is set on the table-distribution component
        if (this.show_negative || this.invert_colors) {
          if (benchmarkItem.perc_neg - item.perc_neg <= -cutoffHigh) return 'highlight_neg'
          if (benchmarkItem.perc_neg - item.perc_neg <= -cutoffLow) return 'highlight_med_neg'
          if (benchmarkItem.perc_neg - item.perc_neg >= cutoffHigh) return 'highlight_pos'
          if (benchmarkItem.perc_neg - item.perc_neg >= cutoffLow) return 'highlight_med_pos'
        } else {
          // Otherwise, highlight normally
          if (benchmarkItem.perc_pos - item.perc_pos <= -cutoffHigh) return 'highlight_pos'
          if (benchmarkItem.perc_pos - item.perc_pos <= -cutoffLow) return 'highlight_med_pos'
          if (benchmarkItem.perc_pos - item.perc_pos >= cutoffHigh) return 'highlight_neg'
          if (benchmarkItem.perc_pos - item.perc_pos >= cutoffLow) return 'highlight_med_neg'
        }
      }

      return 'highlight_neutral'
    },
    getBenchmarkPositive(questiondata, benchmark_id) {
      // Enrich the benchmark with questoin data to grade
      questiondata.benchmarks[benchmark_id].question = questiondata.question
      // Get the indexed counts for getPositive to work
      let benchmarkData = this.getIndexedCounts(questiondata.benchmarks[benchmark_id])

      return this.getPositive(benchmarkData, true)
    },
    getBenchmarkNegative(questiondata, benchmark_id) {
      // Enrich the benchmark with questoin data to grade
      questiondata.benchmarks[benchmark_id].question = questiondata.question
      // Get the indexed counts for getPositive to work
      let benchmarkData = this.getIndexedCounts(questiondata.benchmarks[benchmark_id])

      return this.getNegative(benchmarkData, true)
    }
  }
}
</script>