<template>
  <div style="margin-top: 10px;">
    <!-- Loading state -->
    <div v-if="loading" class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    
    <!-- Error state -->
    <div v-else-if="error" class="text-center">
      {{ error }}
    </div>

    <!-- Card for showing the custom select -->
    <v-card class="choose-between-two" v-else>

      <!-- Card title -->
      <v-card-title class="accent white--text" v-if="content.show_title !== false">

        {{ content.show_title }}
        <h4>{{ questiondata.question.name }}</h4>
        <v-spacer/>
        <span v-if="questiondata.total_count && questiondata.total_count > $store.getters.reportSettings.min_results && currentComparesCount.length === 0" class="text-body-2" >response: {{ questiondata.total_count }}</span>        
      </v-card-title>

      <!-- Card content -->
      <div v-if="questiondata.total_count >= $store.getters.reportSettings.min_results">
        
        <div class="v-card__text" v-if="questiondata.question.name != questiondata.question.question">
          {{ questiondata.question.question }}
        </div>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <!-- Show left / right labels -->
              <tr v-if="questiondata.question && 
                        questiondata.question.metadata && 
                        questiondata.question.metadata.options && 
                        questiondata.question.metadata.options.left && 
                        questiondata.question.metadata.options.right && 
                        questiondata.question.metadata.options.left.value && 
                        questiondata.question.metadata.options.right.value">
                <th v-if="comparingOrBenchmarking"></th>
                <th>{{ questiondata.question.metadata.options.left.value }}</th>
                <th style="text-align: right">{{ questiondata.question.metadata.options.right.value }}</th>
              </tr>
              <tr v-else-if="questiondata.question && 
                        questiondata.question.metadata && 
                        questiondata.question.metadata.options && 
                        questiondata.question.metadata.options.left && 
                        questiondata.question.metadata.options.right">
                <th v-if="comparingOrBenchmarking"></th>
                <th>{{ questiondata.question.metadata.options.left }}</th>
                <th style="text-align: right">{{ questiondata.question.metadata.options.right }}</th>
              </tr>
              <tr v-else>
                <th v-if="comparingOrBenchmarking"></th>
                <th colspan="2" style="text-align: center">{{ questiondata.question.question }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="comparingOrBenchmarking"></td>
                <td colspan="2" class="border-left center-bar" :class="{twoThirds: comparingOrBenchmarking}">
                  <progress-bar-choose :item="questiondata" :negative="content.show_negative || false"/>
                </td>
                <!-- <td>{{ JSON.stringify(questiondata) }}</td> -->
              </tr>

              <!-- Compares -->
              <tr v-for="(compare, compareIndex) in $store.state.currentCompares" :key="'compare-' + compareIndex">
                
                <!-- If the reponse count is enough so we can show the data -->
                <template v-if="compares[compareIndex].total_count && compares[compareIndex].total_count >= $store.getters.reportSettings.min_results">
                  <td>{{ compare.name }}</td>
                  <td colspan="2" class="border-left center-bar">
                    <progress-bar-choose :item="compares[compareIndex]" :negative="content.show_negative || false"/>
                  </td>
                </template>
                
                <!-- Otherwise, we show the response too low tooltip -->
                <template v-else>
                  <td>{{ compare.name }}</td>
                  <td colspan=2 class="text-center border-left">
                    <response-too-low-tooltip />
                  </td>
                </template>
              </tr>

              <!-- Benchmarks -->
              <tr v-for="(benchmark, benchmarkIndex) in $store.state.currentBenchmarks" :key="benchmarkIndex">
                <td class="benchmark">
                  <span v-if="benchmark.type == 'benchmark_project'"><i>Project:</i> {{ benchmark.name }}</span>
                  <span v-else><i>Benchmark:</i> {{ benchmark.name }}</span>
                </td>

                <!-- Project benchmark -->
                <td v-if="benchmark.type === 'benchmark_project'" colspan="2" class="border-left benchmark">
                  <progress-bar-choose :item="questiondata.benchmarks[benchmark.id]" :negative="content.show_negative || false"/>
                </td>

                <!-- Regular/static benchmark -->
                <td v-else-if="benchmark.type !== 'benchmark_project' && benchmark.questions[questiondata.id]" colspan="2" class="border-left benchmark">
                  {{ benchmark.questions[questiondata.id] }}
                </td>

                <!-- None or to low of a response -->
                <td v-else colspan="2" class="border-left benchmark text-center">
                  <response-too-low-tooltip />
                </td>
              </tr>
              
            </tbody>
          </template>
        </v-simple-table>
      </div>

      <template v-else>
          <v-alert :value="true" type="warning">
            {{ $t('interface.onvoldoende_respons') }}
          </v-alert>
      </template>
    </v-card>

  </div>
</template>

<script>
import { NolostInsights } from '@/mixins/NolostInsights'
import ResponseTooLowTooltip from '@/components/reportPageContent/ResponseTooLowTooltip.vue'
import ProgressBarChoose from '@/components/reportPageContent/ProgressBarChoose.vue'

export default {
  name: "ChooseBetweenTwo",
  mixins: [NolostInsights],
  props: ['content'],
  components: {
    ProgressBarChoose,
    ResponseTooLowTooltip
  },
  data () {
    return {
      loading: true,
      questiondata: false,
      error: undefined,
      cancel: undefined,
      hover: false,
      compares: [],
      benchmarks: []
    }
  },
  computed: {
    currentComparesCount () { return this.$store.state.currentCompares },
    currentFiltersCount () { return this.$store.state.currentFilters },
    currentBenchmarksCount () { return this.$store.state.currentBenchmarks },
    currentParticipantCount () { return Math.min(...this.questions.filter(q => q && q.total_count).map(q => q.total_count)) },
    comparingOrBenchmarking() { return Boolean(this.$store.state.currentBenchmarks.length + this.$store.state.currentCompares.length) }
  },
  methods: {
    getData() {
        this.loading = true;
        this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' +
          this.content.questions.join(","))
        .then(resp => {
          this.questiondata = resp.data[0]
          console.log(this.questiondata)
          this.compares = Array(this.$store.state.currentCompares.length || []) // Set the compares
          this.refreshCompares() // Refresh compares
          this.loading = false
        })
        .catch(err => {
          this.error = err
          this.loading = false
        })
      },
    updateCompares(currentCompares, previousCompares) { 
      if (currentCompares && previousCompares && (currentCompares.length > previousCompares.length)) {
        this.addCompare(currentCompares.slice(-1)[0], currentCompares.length - 1)
      } else if ((currentCompares && previousCompares && (currentCompares.length < previousCompares.length)) ) {
        let removedCompareIndex = previousCompares.findIndex(f => !currentCompares.includes(f))
        this.removeCompare(removedCompareIndex)
      } 
    },
    refreshCompares() {
      this.$store.state.currentCompares.forEach((compare, compareIndex) => {
        this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' + this.content.questions.join(",") + '&compare=' + this.$store.state.availableCompares.findIndex(c => compare.key === c.key && compare.value === c.value))
        .then(resp => { 
          let item = resp.data[0]
          item = this.getIndexedCounts(item) // Get the index counts
          item.labels = this.getLabels(item.question.qtype) // Get the labels for each index
          this.compares.splice(compareIndex, 1, item)
        })
        .catch(err => {
          this.error = err
          this.loading = false;
        })
      })
    },
    addCompare(compare, compareIndex) {
      if (compare && compare.key && compare.value) {
        // And get the data related to this compare
        this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' + this.content.questions.join(",") + '&compare=' + this.$store.state.availableCompares.findIndex(c => compare.key === c.key && compare.value === c.value))
        .then(resp => { 
          let item = resp.data[0]
          item = this.getIndexedCounts(item) // Get the index counts
          item.labels = this.getLabels(item.question.qtype) // Get the labels for each index
          this.compares.splice(compareIndex, 1, item)
        })
        .catch(err => {
          this.error = err
          this.loading = false;
        })
      }
    },
    removeCompare(compareIndex) {
      this.compares.splice(compareIndex, 1)
    },
    updateBenchmarks() {
      this.getData()
    },
  },

  mounted() {
    this.getData()
  },
  watch: {
    'currentBenchmarksCount': 'updateBenchmarks',
    'currentComparesCount': 'updateCompares',
    'currentFiltersCount': 'getData',
  }
}
</script>

<style>
/* td.border-left{
  border-left: thin solid rgba(0,0,0,.12);
  border-right: thin solid rgba(0,0,0,.12);
} */
td.center-bar::after{
  content: "";
  height: 24px;
  width: 1px;
  background-color: #C3C9D6;
  display: block;
  position: relative;
  /* z-index: 10; */
  margin-left: 50%;
  top: -10px;
  /* left: 50%; */
  /* margin: 50%; */
  /* left: 50% */
  /* position: absolute; */
}

td.twoThirds{
  width: 66%
}
</style>