<template>
  <span>
    <!-- If no data is found -->
    <insufficient-data-tooltip v-if="benchmark && item && !findBenchmarkWithQuestionId(item.question.id)"/>

    <!-- Or the score of the benchmark is set to zero (for incomplete benchmarks that have a 0.0 in their response) -->
    <insufficient-data-tooltip v-else-if="benchmark && item && findBenchmarkWithQuestionId(item.question.id).score === 0.0"/>

    <v-chip v-else-if="findBenchmarkWithQuestionId(item.question.id) && show_score"
      :class="highlight(findBenchmarkWithQuestionId(item.question.id), item)"
      class="score">
      {{ findBenchmarkWithQuestionId(item.question.id).score.toFixed(0) }}
    </v-chip>

    <v-chip v-else-if="findBenchmarkWithQuestionId(item.question.id) && !show_score && show_negative"
      :class="highlight(findBenchmarkWithQuestionId(item.question.id), item)"
      class="score">
      {{ (findBenchmarkWithQuestionId(item.question.id).perc_neg * 100).toFixed(0) }}%
    </v-chip>

    <v-chip v-else-if="findBenchmarkWithQuestionId(item.question.id) && !show_score"
      :class="highlight(findBenchmarkWithQuestionId(item.question.id), item)"
      class="score">
      {{ (findBenchmarkWithQuestionId(item.question.id).perc_pos * 100).toFixed(0) }}%
    </v-chip>

  </span>
</template>

<script>
import InsufficientDataTooltip from '@/components/reportPageContent/InsufficientDataTooltip.vue'

export default {
  name: "QuestionBenchmark",
  props: [
    'item',
    'benchmark',
    'invert_colors',
    'show_score',
    'show_negative'
  ],
  components: {
    InsufficientDataTooltip,
  },
  methods: {
    findBenchmarkWithQuestionId(questionId) {
      if (this.benchmark.questions) {
        return this.benchmark.questions.find(benchmarkQuestion => benchmarkQuestion.question === questionId)
      } else {
        return false
      }
    },
    // highlight(score, item) {
    //   // If the question is asked negatively and needs to be inverted
    //   if ((item.question.is_negative && item.question.qtype.startsWith('7-point-likert')) || this.invert_colors) {
    //     if (score - item.score >= item.std_dev) return 'highlight_pos'
    //     if (score - item.score >= item.std_dev / 2) return 'highlight_med_pos'
    //     if (score - item.score <= -item.std_dev) return 'highlight_neg'
    //     if (score - item.score <= -item.std_dev / 2) return 'highlight_med_neg'
    //   }
    //   // Otherwise, highlight normally
    //   if (score - item.score >= item.std_dev) return 'highlight_neg'
    //   if (score - item.score >= item.std_dev / 2) return 'highlight_med_neg'
    //   if (score - item.score <= -item.std_dev) return 'highlight_pos'
    //   if (score - item.score <= -item.std_dev / 2) return 'highlight_med_pos'
    // },
    highlight(benchmarkItem, item) {
      // console.log(benchmarkItem, item)
      
      // // When showing a score, we need different cuttoffs and calculations
      if (this.show_score) {
        let cutoffLow = 0.5 // item.std_dev * 0.125
        let cutoffHigh = 1 // item.std_dev * 0.25
        
        // If the question is asked negatively or invert_colors is set on the table-distribution component
        if (this.show_negative || this.invert_colors) {
          if (benchmarkItem.score - item.score <= -cutoffHigh) return 'highlight_neg'
          if (benchmarkItem.score - item.score <= -cutoffLow) return 'highlight_med_neg'
          if (benchmarkItem.score - item.score >= cutoffHigh) return 'highlight_pos'
          if (benchmarkItem.score - item.score >= cutoffLow) return 'highlight_med_pos'
        } else {
          // Otherwise, highlight normally
          if (benchmarkItem.score - item.score <= -cutoffHigh) return 'highlight_pos'
          if (benchmarkItem.score - item.score <= -cutoffLow) return 'highlight_med_pos'
          if (benchmarkItem.score - item.score >= cutoffHigh) return 'highlight_neg'
          if (benchmarkItem.score - item.score >= cutoffLow) return 'highlight_med_neg'
        }
      // // Otherwise, we use the percentages
      } else {
        // console.log(benchmarkItem.perc_pos, item.perc_pos)
        let cutoffLow = 0.05 // item.std_dev * 0.125
        let cutoffHigh = 0.10 // item.std_dev * 0.25

        // If the question is asked negatively or invert_colors is set on the table-distribution component
        if (this.show_negative || this.invert_colors) {
          if (benchmarkItem.perc_neg - item.perc_neg <= -cutoffHigh) return 'highlight_neg'
          if (benchmarkItem.perc_neg - item.perc_neg <= -cutoffLow) return 'highlight_med_neg'
          if (benchmarkItem.perc_neg - item.perc_neg >= cutoffHigh) return 'highlight_pos'
          if (benchmarkItem.perc_neg - item.perc_neg >= cutoffLow) return 'highlight_med_pos'
        } else {
          // Otherwise, highlight normally
          if (benchmarkItem.perc_pos - item.perc_pos <= -cutoffHigh) return 'highlight_pos'
          if (benchmarkItem.perc_pos - item.perc_pos <= -cutoffLow) return 'highlight_med_pos'
          if (benchmarkItem.perc_pos - item.perc_pos >= cutoffHigh) return 'highlight_neg'
          if (benchmarkItem.perc_pos - item.perc_pos >= cutoffLow) return 'highlight_med_neg'
        }
      }

      return 'highlight_neutral'
    },
    getBenchmarkPositive(questiondata, benchmark_id) {
      // Enrich the benchmark with questoin data to grade
      questiondata.benchmark_reports[benchmark_id].question = questiondata.question
      // Get the indexed counts for getPositive to work
      let benchmarkData = this.getIndexedCounts(questiondata.benchmark_reports[benchmark_id])

      return this.getPositive(benchmarkData, true)
    },
    getBenchmarkNegative(questiondata, benchmark_id) {
      // Enrich the benchmark with questoin data to grade
      questiondata.benchmark_reports[benchmark_id].question = questiondata.question
      // Get the indexed counts for getPositive to work
      let benchmarkData = this.getIndexedCounts(questiondata.benchmark_reports[benchmark_id])

      return this.getNegative(benchmarkData, true)
    }
  }
}
</script>