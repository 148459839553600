var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.negative)?_c('div',{staticClass:"progress-bar"},[_vm._v(" "+_vm._s(_vm.item.perc_)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"progress-bar-item error",style:(("width: " + (_vm.item.perc_neg * 100) + "%"))},'div',attrs,false),on))]}}],null,false,901902108)},[_c('span',[_vm._v(" "+_vm._s(_vm._f("dutch_number")((_vm.item.perc_neg * 100).toFixed(1)))+"% "+_vm._s(_vm.$t('antwoorden.progress-bar.negative'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"progress-bar-item gray",style:(("width: " + ((_vm.item.perc_neutral * 100)) + "%"))},'div',attrs,false),on))]}}],null,false,2708243153)},[_c('span',[_vm._v(" "+_vm._s(_vm._f("dutch_number")((_vm.item.perc_neutral * 100).toFixed(1)))+"% "+_vm._s(_vm.$t('antwoorden.progress-bar.neutral'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"progress-bar-item positive",style:(("width: " + (_vm.item.perc_pos * 100) + "%"))},'div',attrs,false),on))]}}],null,false,4203311759)},[_c('span',[_vm._v(" "+_vm._s(_vm._f("dutch_number")((_vm.item.perc_pos * 100).toFixed(1)))+"% "+_vm._s(_vm.$t('antwoorden.progress-bar.positive'))+" ")])])],1):_c('div',{staticClass:"progress-bar"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"progress-bar-item positive",style:(("width: " + (_vm.item.perc_pos * 100) + "%"))},'div',attrs,false),on))]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm._f("dutch_number")((_vm.item.perc_pos * 100).toFixed(1)))+"% "+_vm._s(_vm.$t('antwoorden.progress-bar.positive'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"progress-bar-item gray",style:(("width: " + ((_vm.item.perc_neutral * 100)) + "%"))},'div',attrs,false),on))]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm._f("dutch_number")((_vm.item.perc_neutral * 100).toFixed(1)))+"% "+_vm._s(_vm.$t('antwoorden.progress-bar.neutral'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"progress-bar-item error",style:(("width: " + (_vm.item.perc_neg * 100) + "%"))},'div',attrs,false),on))]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm._f("dutch_number")((_vm.item.perc_neg * 100).toFixed(1)))+"% "+_vm._s(_vm.$t('antwoorden.progress-bar.negative'))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }