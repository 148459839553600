<template>
  <div>
    <div v-if="loading" class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else-if="error" class="text-center">
      { error }
    </div>
    <div v-else>
      <v-data-table
        :headers="getHeaders()"
        :items="this.questions"
        :calculate-widths="true"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        hide-default-footer
        disable-pagination
        class="elevation-1 mb-4">
      
        <template v-slot:header="{ getHeaders }">
          <thead>
            <tr>
              {{ getHeaders }}
            </tr>
          </thead>
        </template>

        <template v-slot:item="{ item, index }">  
          <tr>
            <template>
              <td style="min-width: 300px; position: sticky">
                <span v-if="item.question.question">{{ templateQuestion(item.question.question, $store.getters.reportSettings) }}</span>
                <span v-else>{{ item.question.name }}</span>
                <negative-question-tooltip :show_score="content.show_score || false" :questiondata="item.question" v-if="item.question.is_negative" />
              </td>
            </template>
            <template v-if="loadingFilter">
              <td class="text-center">
                <v-progress-circular
                  :size="20"
                  :width="2"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </td>
            </template>
            <template v-else-if="item.score && item.total_count >= $store.getters.reportSettings.min_results">
              <td class="text-center">
                {{ item.score.toFixed(1) | dutch_number }}
              </td>
            </template>
            <template v-else>
              <td class="text-center"><response-too-low-tooltip /></td>
            </template>
            <template v-for="(compare, compareIndex) in $store.state.currentCompares">
              <td 
                v-if="loadingFilter"
                class="text-center"
                :key="item.question.id + '-' + compareIndex + '-' + index">
                <v-progress-circular
                  :size="20"
                  :width="2"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </td>
              <td
                v-else-if="item.compares[compareIndex] && item.compares[compareIndex].total_count && item.compares[compareIndex].total_count < $store.getters.reportSettings.min_results"
                :key="item.question.id + '-' + compareIndex + '-' + index"
                class="text-center">
                <response-too-low-tooltip />
              </td>
              <!-- Show a compare -->
              <td 
                v-else-if="item.compares[compareIndex] && item.compares[compareIndex].score && item.compares[compareIndex].total_count >= $store.getters.reportSettings.min_results" 
                :key="item.question.id + '-' + compareIndex + '-' + index" 
                :class="highlight(item.compares[compareIndex].score, item)"
                class="text-center">
                {{ item.compares[compareIndex].score.toFixed(1) | dutch_number }}
              </td>
              <!-- Show the response to low -->
              <td 
                v-else-if="item.compares[compareIndex]" 
                :key="item.question.id + '-' + compareIndex + '-' + index" 
                class="text-center">
                <response-too-low-tooltip />
              </td>
              <!-- Show loading -->
              <td 
                v-else
                class="text-center"
                :key="item.question.id + '-' + compareIndex + '-' + index">
                <v-progress-circular
                  :size="20"
                  :width="2"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </td>
            </template>
            <template v-for="(benchmark, benchmarkIndex) in $store.state.currentBenchmarks">
              <!-- Show a response too low -->
              <td
                v-if="item.benchmark_reports[benchmark.id] && item.benchmark_reports[benchmark.id].total_count < $store.getters.reportSettings.min_results"
                class="benchmark score text-center"
                :key="'benchmark-value-' + benchmarkIndex">
                <response-too-low-tooltip/>
              </td>
              <!-- Try rendering a project benchmark if it's set-->
              <td 
                v-else-if="item.benchmark_reports[benchmark.id] && item.benchmark_reports[benchmark.id].score"
                :class="highlight(item.benchmark_reports[benchmark.id].score, item)"
                :key="'benchmark-value-' + benchmarkIndex"
                class="benchmark score text-center">
                {{ parseFloat(item.benchmark_reports[benchmark.id].score).toFixed(1) }}
              </td>
              
              <!-- Otherwise, try rendering a normal benchmark -->
              <td v-else
                :key="'benchmark-value-' + benchmarkIndex"
                class="benchmark text-center">
                <question-benchmark 
                :item="item" 
                :benchmark="benchmark" 
                :invert_colors="content.invert_colors || false"
                />
              </td>
            </template>
          </tr>
        </template>

        <template v-slot:body.append>
          <tr style="background-color: #ebedee">
            <th>
              <i>
                <span v-if="$store.state.currentFilters.length > 0">{{ $tc('interface.participant_filtered') }}</span>
                <span v-else>{{ $tc('interface.participant_count') }}</span>
              </i>
            </th>
            <td v-if="loadingFilter" class="text-center">
                <v-progress-circular
                  :size="20"
                  :width="2"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
            </td>
            <td v-else-if="currentParticipantCount < $store.getters.reportSettings.min_results || currentParticipantCount === Infinity" class="text-center">
              <response-too-low-tooltip />
            </td>
            <td v-else class="text-center">
              {{ currentParticipantCount }}
            </td>
            <!-- <td v-else class="text-center">
              {{ Math.min(...questions.filter(q => q && q.total_count).map(q => q.total_count)) }}
            </td> -->
            <template v-for="(compare, compareIndex) in $store.state.currentCompares">
              <td
                v-if="loadingFilter" 
                class="text-center"
                :key=compareIndex >
                <v-progress-circular
                  :size="20"
                  :width="2"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </td>
              <td
                v-else-if="questions.filter(q => q.compares[compareIndex]).map(q => q.compares[compareIndex]).length > 0"
                :key=compareIndex 
                class="text-center">
                <span v-if="Math.min(...questions.filter(q => q.compares[compareIndex]).map(q => q.compares[compareIndex].total_count)) < $store.getters.reportSettings.min_results">
                  <response-too-low-tooltip />
                </span>
                <span v-else>
                  {{ Math.min(...questions.filter(q => q.compares[compareIndex]).map(q => q.compares[compareIndex].total_count)) }}
                </span>
              </td>
              <td 
                v-else
                class="text-center"
                :key="compareIndex">
                <v-progress-circular
                  :size="20"
                  :width="2"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </td>
            </template>

            <template v-for="(benchmark, benchmarkIndex) in $store.state.currentBenchmarks">
              <td v-if="benchmark.type === 'benchmark_project'" class="benchmark-bottom text-center" :key="'benchmark_' + benchmarkIndex">
                <span v-if="Math.min(...questions.filter(q => q.benchmark_reports[benchmark.id]).map(q => q.benchmark_reports[benchmark.id].total_count)) < $store.getters.reportSettings.min_results">
                  <response-too-low-tooltip />
                </span>
                <span v-else>
                  {{ Math.min(...questions.filter(q => q.benchmark_reports[benchmark.id]).map(q => q.benchmark_reports[benchmark.id].total_count)) }}
                </span>
              </td>
              <!-- If it isn't a benchmark_project, we cannot provide a response count -->
              <!-- If it isn't a benchmark_project, we cannot provide a response count -->
              <td v-else class="benchmark-bottom text-center" :key="'benchmark_' + benchmarkIndex">
                <insufficient-data-tooltip />
              </td>
            </template>
          </tr>
        </template>

        <template v-slot:no-data>
          <tr>
            <td colspan="3">
              Loading data
            </td>
          </tr>
        </template>

        <template v-slot:no-results>
          <tr class="red lighten-1">
            <td colspan="3">
              Unable to load data..
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    
  </div>
</template>

<script>
import { NolostInsights } from '@/mixins/NolostInsights'
import QuestionBenchmark from '@/components/reportPageContent/QuestionBenchmark.vue'
import NegativeQuestionTooltip from '@/components/reportPageContent/NegativeQuestionTooltip.vue'
import ResponseTooLowTooltip from '@/components/reportPageContent/ResponseTooLowTooltip.vue'
import InsufficientDataTooltip from '@/components/reportPageContent/InsufficientDataTooltip.vue'

export default {
  name: "TableContent",
  mixins: [NolostInsights],
  props: ['content'],
  components: {
    NegativeQuestionTooltip,
    ResponseTooLowTooltip,
    InsufficientDataTooltip,
    QuestionBenchmark,
  },
  data () {
    return {
      loading: true,
      loadingFilter: false,
      questions: [],
      valueType: 'percentage',
      error: undefined,
      cancel: undefined,
      sortBy: 'index',
      sortDesc: false,
    }
  },
  computed: {
    currentComparesCount () { return this.$store.state.currentCompares },
    currentFiltersCount () { return this.$store.state.currentFilters },
    currentBenchmarksCount () { return this.$store.state.currentBenchmarks },
    currentParticipantCount () { return Math.min(...this.questions.filter(q => q && q.total_count).map(q => q.total_count)) }
  },
  methods: {
    getData () {
      this.loading = true;

      this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' + this.content.questions.join(","))
      .then(resp => {
        this.questions = resp.data.map(item => {
          item.compares = Array(this.$store.state.currentCompares.length || [])
          return item
        })
        this.loading = false;
        this.refreshCompares()
      })
      .catch(err => {
        this.error = err        
        this.loading = false;
      })
    },
    updateCompares(currentCompares, previousCompares) { 
      // When adding a compare
      if (currentCompares && previousCompares && (currentCompares.length > previousCompares.length)) {
        this.addCompare(currentCompares.slice(-1)[0], currentCompares.length - 1)

      // When removing a compare
      } else if ((currentCompares && previousCompares && (currentCompares.length < previousCompares.length)) ) {
        let removedCompareIndex = previousCompares.findIndex(f => !currentCompares.includes(f))
        this.removeCompare(removedCompareIndex)
      }
    },
    // When a filter is changed, refresh the compares too
    refreshCompares() {
      this.$store.state.currentCompares.forEach((compare, compareIndex) => {
        this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' + this.content.questions.join(",") + '&compare=' + this.$store.state.availableCompares.findIndex(c => compare.key === c.key && compare.value === c.value))
        .then(resp => { 
          resp.data.forEach(item => {
            let questionIndex = this.questions.findIndex(question => { return question.question.id === item.question.id})
            if (questionIndex >= 0) {
              this.questions[questionIndex].compares.splice(compareIndex, 1, item)
            }
          })
        })
        .catch(err => {
          this.error = err
          this.loading = false;
        })
      })
    },
    addCompare(compare, compareIndex) {
      if (compare && compare.key && compare.value) {
        // And get the data related to this compare
        this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' + this.content.questions.join(",") + '&compare=' + this.$store.state.availableCompares.findIndex(c => compare.key === c.key && compare.value === c.value))
        .then(resp => { 
          resp.data.forEach(item => {
            let questionIndex = this.questions.findIndex(question => { return question.question.id === item.question.id})
            if (questionIndex >= 0) {
              this.questions[questionIndex].compares.splice(compareIndex, 1, item)
            }
          })
        })
        .catch(err => {
          this.error = err
          this.loading = false;
        })
      }
    },
    removeCompare(compareIndex) {
      this.questions.forEach(question => {
        question.compares.splice(compareIndex, 1)
      })
    },
    updateFilters() {
      this.loadingFilter = true
      let oldData = this.questions
      
      // Cancel the pending request
      if (this.cancel) { this.cancel.abort() }

      // Create a new cancel controller
      this.cancel = new AbortController();

      // Make the query
      this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' +
        this.content.questions.join(","), {signal: this.cancel.signal})
      .then(resp => { 

        // Update the filters
        oldData.forEach((question, index) => {
          resp.data[index].compares = oldData[index].compares
        })
        
        this.questions = resp.data
        this.refreshCompares() // Refresh compares
        this.loadingFilter = false;
      })
      .catch(err => {
        this.error = err
        this.loading = false;
      })
    },
    getHeaders() {
      let headers = [
        { text: 'Question', sortable: true, align: 'start', value: 'question.name'},
      ]

      if (this.$store.state.currentFilters.length > 0) {
        headers.push({ text: 'Score (filtered)', sortable: true, align: 'center', width: `70xpx`, value: 'score'})
      } else {
        headers.push({ text: 'Score', sortable: true, align: 'center', width: `70xpx`, value: 'score'})
      }


      if (this.$store.state.currentCompares.length > 0) {
        this.$store.state.currentCompares.forEach(compare => {
          headers.push({text: compare.name, align: 'center', width: '120px', sortable: false})
        })
      }
      
      if (this.$store.state.currentBenchmarks) { 
        this.$store.state.currentBenchmarks.forEach(benchmark => {
          headers.push({text: benchmark.name, align: 'center', width: '120px', class: "benchmark", sortable: false})
        })
      }

      return headers
    },
    highlight (score, item) {
      // If the question is asked negatively or invert_colors is set on the table-content component
      if ((item.question.is_negative && item.question.qtype.startsWith('7-point-likert')) || this.content.invert_colors) {
        if (score - item.score >= item.std_dev) return 'highlight_pos'
        if (score - item.score >= item.std_dev / 2) return 'highlight_med_pos'
        if (score - item.score <= -item.std_dev) return 'highlight_neg'
        if (score - item.score <= -item.std_dev / 2) return 'highlight_med_neg'
      }
      // Otherwise, highlight normally
      if (score - item.score >= item.std_dev) return 'highlight_neg'
      if (score - item.score >= item.std_dev / 2) return 'highlight_med_neg'
      if (score - item.score <= -item.std_dev) return 'highlight_pos'
      if (score - item.score <= -item.std_dev / 2) return 'highlight_med_pos'

      return 'highlight_neutral'
    }
  },
  filters: {
    getValue: function(arr, type, count, totalCount) {
      if (type === 'count') {
        let item = arr.filter(item => item.value === count)
        if (item.length !== 0) {
          return item[0].count
        } 
        return 0
      } else if (type === 'percentage') {
        if (totalCount === 0) {
          return "0%"
        }
        let item = arr.filter(item => item.value === count)
        if (item.length !== 0) {
          return ((item[0].count / totalCount) * 100).toFixed(0) + "%"
        }
        return "0%"
      }
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    'currentComparesCount': 'updateCompares',
    'currentFiltersCount': 'updateFilters',
    'currentBenchmarksCount': 'getData',
  }
}
</script>

<style scoped>
.highlight_med_pos {
  background-color: #7ED5C1;
}
.highlight_pos {
  background-color: #00ac85;
}
.highlight_med_neg {
  background-color: #F17D9A;
}
.highlight_neg {
  background-color: #e4003a;
}
</style>