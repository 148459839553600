<template>
  <div class="progress-choose">
    <div class="progress-bar-slider" v-if="widths.length > 4 && item.avg">
      
      <div 
        :style="`width: calc(${(widths[0])}% - 26px)`" 
        class="progress-bar-item">
      </div>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div 
            :style="`width: calc(${(widths[1])}% - 26px)`" 
            v-bind="attrs"
            v-on="on"
            class="progress-bar-item progress-bar-light">
          </div>
        </template>
        <span>
          <!-- {{ widths[1].toFixed() }}%  -->
          Std. Deviatie
        </span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div  
            :style="`width: 26px`" 
            v-bind="attrs"
            v-on="on"
            class="progress-bar-item center">
          </div>
        </template>
        <span>
          <!-- {{ this.avg.toFixed() }}% -->
          Gemiddelde
        </span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div 
            :style="`width: calc(${(widths[3])}% - 26px)`" 
            v-bind="attrs"
            v-on="on"
            class="progress-bar-item progress-bar-light">
          </div>
        </template>
        <span>
          <!-- {{ widths[3].toFixed() }}%  -->
          Std. Deviatie
        </span>
      </v-tooltip>

      <div 
        :style="`width: calc(${(widths[4])}% - 26px)`" 
        class="progress-bar-item">
      </div>

    </div>
    <div v-else class="text-center">
      <insufficient-data-tooltip />
    </div>
  </div>
</template>

<script>
import { NolostInsights } from '@/mixins/NolostInsights'
import InsufficientDataTooltip from '@/components/reportPageContent/InsufficientDataTooltip.vue'

export default {
  name: "ProgressBarChoose",
  props: ['item', 'negative'],
  mixins: [NolostInsights],
  components: {
    InsufficientDataTooltip
  },
  data () {
    return {
      widths: [],
    }
  },
  methods: {
    setData() {
      if (this.item && this.item.avg) {
        this.avg = (this.item.avg + 5) * 10
        this.rsd = (this.item.std_dev * 100) / this.avg // relative standard deviation
  
        this.widths = [
          this.avg - (this.rsd * 2),
          (this.rsd * 2),
          null, // The center point is always 26px
          (this.rsd * 2),
          100 - (this.avg + (this.rsd * 2)),
        ] 
      }
    }
  },
  mounted() {
    this.setData()
  },
}    
</script>


<style scoped>
  .progress-choose{
    min-width: 200px
  }
  .progress-bar-slider{
    height: 15px;
    background-color: #E7E9EE;
    border-radius: 8px;
    width: 100%;
    display: block;
    margin-top: 16px;
    margin-bottom: -10px;
  }

  .progress-bar-item{
    /* margin-top: 10px; */
    /* z-index: 10; */
    float: left;
    height: 15px;
    box-sizing: border-box;
    display: inline;
  }
  .center{
    display:inline-block;
    color: white;
    height: 26px;
    width: 26px;
    position: relative;
    transform: translate(0px, -5px);
    border-radius: 13px;
    background-color: #009BD9;
  }
</style>