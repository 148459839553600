<template>
  <v-layout class="component-count-per-score-round">
    <div v-if="loading" class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-card v-for="(questiondata, index) in questions" :key="index" class="mb-3">
        <v-card-title class="accent white--text">
          {{ templateQuestion(questiondata.question.question || questiondata.question.name, $store.getters.reportSettings) }}
          <negative-question-tooltip :questiondata="questiondata.question" v-if="questiondata.question.is_negative" />
          <v-spacer />
          <span class="text-body-2" v-if="questiondata.total_count > $store.getters.reportSettings.min_results">{{ $tc('interface.participant_count') }}: {{ questiondata.total_count }}</span>
        </v-card-title>
        <v-card-text>
          <v-container v-if="questiondata.total_count >= $store.getters.reportSettings.min_results">
            <v-row align="center" justify="center">
              <v-col md="4" cols="12" class="text-center text-h1 font-weight-light" fill-height>
                <v-progress-circular
                  :rotate="270"
                  :size="200"
                  :width="20"
                  :value="questiondata.score * 10"
                  :color="getRedGreenColor(questiondata.score / 10)"
                >
                  {{ (questiondata.score).toFixed(1) | dutch_number}}
                </v-progress-circular>
              </v-col>
              <v-col md="4" cols="12" class="text-center font-weight-light">
                <div style="max-width: 325px">
                  <doughnut-chart :chart-data="getChartData(index)" :options="chartOptions"></doughnut-chart>
                </div>
              </v-col>
              <v-col md="4" cols="12" class="text-center font-weight-light">
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Score
                        </th>
                        <th class="text-left">
                          Aantal
                        </th>
                        <th class="text-left">
                          %
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(label, i) in getLabels(questiondata.question.qtype)"
                        :key="i"
                        :class="getColourClass(i, questiondata.question)"
                      >
                        <td>{{ $t(label) }}</td>
                        <td>{{ questiondata.counts_i[i+1] | dutch_number }}</td>
                        <td>{{ (questiondata.counts_i[i+1] / questiondata.total_count * 100).toFixed(1) | dutch_number }}%</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12" cols="12">
                <question-benchmark :benchmark="questiondata.benchmark"/>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-else>
            <v-alert :value="true" type="warning">
              {{ $t('interface.onvoldoende_respons') }}
            </v-alert>
          </v-container>
        </v-card-text>
      </v-card>
    
    </div>
  </v-layout>
</template>

<script>
  import { NolostInsights } from '@/mixins/NolostInsights'
  import DoughnutChart from '@/plugins/charts/DoughnutChart.js'
  import NegativeQuestionTooltip from '@/components/reportPageContent/NegativeQuestionTooltip.vue'
  import QuestionBenchmark from '@/components/reportPageContent/QuestionBenchmark.vue'

  export default {
    name: "CountPerScoreRound",
    mixins: [NolostInsights],
    props: ['content'],
    components: {
      DoughnutChart,
      QuestionBenchmark,
      NegativeQuestionTooltip
    },
    data () {
      return {
        questions: false,
        labels: false,
        response: "-",
        loading: true,
        datacollection: null,
        chartOptions: {legend: {position: 'left'},},
        colorPalette: this.getPalette(this.content)
      }
    },
    methods: {
      negative_display (question) {
        if (question.qtype.startsWith("grade-1-10")) {
          return !question.is_negative
        }
        else
          return question.is_negative
      },
      getData () {
        this.loading = true;

        this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' +
                this.content.questions.join(","))
          .then(resp => {
            this.questions = []
            for (let i = 0; i < resp.data.length; i++) {
              this.questions[i] = this.getIndexedCounts(resp.data[i])
              this.questions[i].labels = this.getLabels(this.questions[i].question.qtype)
              this.questions[i].benchmark = this.$store.getters.getBenchmarkForQuestionId(this.questions[i].question.id)
            }
            this.loading = false
          })
          .catch(err => {
            this.error = err
            this.loading = false
          })
      },
      getChartData(index){
        return {
          datasets: [{
            data: [
              this.getNegative(this.questions[index], true),
              this.getNeutral(this.questions[index], true),
              this.getPositive(this.questions[index], true),
            ],
            backgroundColor: ["#e4003a", "#f1a135", "#00ac85"],
            hoverBackgroundColor: ["#e4003a", "#f1a135", "#00ac85"],
          }],
          labels: ["negatief", "neutraal", "positief"],
          options: {
            legend: {position: 'bottom'},
          }
        }
      },
    },
    mounted() {
      this.getData()
    },
    watch: {
      'currentFiltersCount': 'getData',
    }
  }
</script>

<style>
  @media print {
    div.component-count-per-score-round {
      break-inside: avoid !important;
      page-break-inside: avoid !important;
    }
    div.component-count-per-score-round div.v-progress-circular {
      width: 100px !important;
      height: 100px !important;
    }
    div.component-count-per-score-round div.v-progress-circular__info {
      font-size: 3rem;
      line-heigt: 3rem;
    }
    div.component-count-per-score-round .doughnut-chart-container {
      width: 225px !important;
    }
    div.component-count-per-score-round .doughnut-chart-container canvas {
      width: 215px !important;
      height: 215px !important;
    }
  }
</style>
