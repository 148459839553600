<template>

  <v-card>
    <v-card-title class="accent white--text">
        {{ content.title }}
    </v-card-title>
    <v-card-text>
        <template v-for="(item, index) in content.items">

            <v-layout align-center fill-height :key="index" mb-4 mt-4>
              <v-flex xs1>
                <v-avatar color="secondary" size="72">
                  <span class="white--text headline">{{ index + 1 }}</span>
                </v-avatar>
              </v-flex>
              <v-flex xs11>
                <v-card-title v-html="item.text" class="subtitle-1" />
              </v-flex>
            </v-layout>

        </template>
    </v-card-text>
  </v-card>

</template>

<script>
    export default {
        name: "ListContent",
        props: ['content'],
    }
</script>

<style>
  .v-card__text, .v-card__title {
    word-break: normal;
  }
</style>
