<template>
  <v-card v-if="this.item.question">
    <v-card-title class="accent white--text">
      {{ templateQuestion(this.item.question.question, $store.getters.reportSettings) }}
      <v-spacer/>
      <span class="text-body-2" v-if="this.item.total_count >= $store.getters.reportSettings.min_results">response: {{ this.item.total_count }}</span>
    </v-card-title>
    <v-card-text v-if="this.item.total_count >= $store.getters.reportSettings.min_results">
      <v-flex class="font-weight-light">
        <v-text-field
          v-if="showSearch"
          v-model="search"
          append-icon="mdi-magnify"
          label="Zoeken"
          single-line
          hide-details
        ></v-text-field>
        <v-data-table
          :headers="headers"
          :hide-default-header="true"
          :items="answers"
          :items-per-page="15"
          :footer-props="{'items-per-page-options': [5, 15, 30, 50, -1]}"
          class="elevation-1"
          :search="search">
          <template v-slot:item="{ item }">
            <tr>
              <td style="white-space: pre-wrap;">{{ item.answer }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-card-text>
    <v-card-text v-else>
      <v-alert :value="true" type="warning">
        {{ $t('interface.onvoldoende_respons') }}
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
  import { NolostInsights } from '@/mixins/NolostInsights'

  export default {
    name: "OpenQuestion",
    mixins: [NolostInsights],
    props: ['content'],
    data () {
      return {
        item: {},
        headers: [{text: 'Antwoord', value: 'answer'}],
        answers: [],
        search: '',
      }
    },
    methods: {
      getData () {
        this.loading = true;

        this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' + this.content.question)
          .then(resp => {
            this.item = resp.data[0]
            this.answers = this.item.answers.map(answer => ({ answer }));
          })
          .catch(err => {
            this.error = err
            this.item = undefined
            this.loading = false;
          })
      },
    },
    mounted () {
      this.getData()
    },
    computed: {
      currentComparesCount () { return this.$store.state.currentCompares },
      currentFiltersCount () { return this.$store.state.currentFilters },
      showSearch () {  this.content.search === undefined ? true : this.content.search
        if (this.content.search === undefined) {
          return true
        }
        return this.content.search
      },
    },
    watch: {
      'currentFiltersCount': 'getData',
      '$route': 'getData'
    },
  }
</script>

<style>
  .v-card__text, .v-card__title {
    word-break: normal;
  }
</style>
