<template>
  <div>
    <!-- Loading state -->
    <div v-if="loading" class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>

    <!-- Card for showing the custom select -->
    <v-card class="custom-select" v-else>
      <!-- Card title -->
      <v-card-title class="accent white--text">
        {{ templateQuestion(questiondata.question.question || questiondata.question.name, $store.getters.reportSettings) }}
        <v-spacer/>
        <span v-if="questiondata.total_count > $store.getters.reportSettings.min_results" class="text-body-2" >{{ $tc('interface.participant_count') }}: {{ currentParticipantCount }}</span>        
      </v-card-title>

      <!-- Show a warning if benchmarks or companres are selected (since they are not supported currently) -->
      <v-alert v-if="currentBenchmarksCount > 0" style="margin-top: 15px;" :value="true" type="warning">
        <span>
          {{ $t('interface.benchmarks_not_supported') }}
        </span>
      </v-alert>

      <!-- Card content -->
      <div v-if="questiondata.total_count >= $store.getters.reportSettings.min_results">
          
        <small class="float-right" style="padding: 5px; display: block;">
          Sorteer percentage:
          <button @click="sortByIndex()" class="accent--text">Oorspronkelijk</button>
          <span> | </span>
          <button @click="sortByValue(-1)" class="accent--text">Aflopend</button>
          <span color="primary"> | </span>
          <button @click="sortByValue()" class="accent--text">Oplopend</button> |
          <button @click="sortByName()" class="accent--text">Alfabetisch</button>
        </small>

        <v-data-table
          style="clear: both"
          :headers="getHeaders()"
          :items="Object.values(questiondata.answers)"
          item-key="index"
          :calculate-widths="true"
          hide-default-footer
          disable-pagination>
          <template v-slot:header="{ getHeaders }">
            {{ getHeaders }}
          </template>
          <template v-slot:item="{ item, index }">  
            <tr>
              <td>{{ item.value }}</td>
              <td class="text-center border-left" style="border-right: 0px;" @click="showDetailScore = !showDetailScore" :class="{'show-details-score' : showDetailScore}">
                <v-chip class="score">
                  {{ Math.round(item.count / questiondata.total_count * 100) | dutch_number }}%
                  <!-- <small style="margin-left: 5px;" text-xs>({{ item.count }})</small> -->
                </v-chip>
                <div class="float-end">
                  <progress-bar-single v-if="showDetailScore" :percentage="Math.round(item.count / questiondata.total_count * 100)"></progress-bar-single>
                </div>
              </td>
              
              <template v-for="(compare, compareIndex) in $store.state.currentCompares">
                
                <template 
                  v-if="compares[compareIndex] && compares[compareIndex].total_count < $store.getters.reportSettings.min_results">
                  <td
                    :key="questiondata.question.id + '-' + compareIndex + '-' + index"
                    class="text-center border-left">
                    <response-too-low-tooltip />
                  </td>
                </template>
                
                <template v-else>
                  <td
                    class="text-center border-left"
                    :key="questiondata.question.id + '_' + compareIndex + '-' + index"
                    v-if="!compares[compareIndex]">
                    <v-progress-circular
                      :size="20"
                      :width="2"
                      indeterminate
                      color="primary">
                    </v-progress-circular>
                  </td>

                  <td class="text-center border-left"
                    v-if="compares[compareIndex] && compares[compareIndex].answers && compares[compareIndex].answers[index]"
                    :key="questiondata.question.id + '_' + compareIndex + '-' + index"
                    :class="{'show-details-compare' : compares[compareIndex].showDetail}"
                    @click="toggleDetailCompare(compareIndex)">

                    <v-chip class="score" :class="highlight(compares[compareIndex], index)">
                      {{ Math.round(compares[compareIndex].answers[index].count / compares[compareIndex].total_count * 100) | dutch_number }}%
                    </v-chip>
                    <div class="float-end">
                      <progress-bar-single v-if="compares[compareIndex].showDetail" :percentage="Math.round(compares[compareIndex].answers[index].count / compares[compareIndex].total_count * 100)"></progress-bar-single>
                    </div>
                  </td>
                </template>
              </template>
            </tr>
          </template>

          <template v-slot:[`body.append`]>
            <tr class="table-bottom" v-if="currentComparesCount.length > 0">
              <td>
                <i>
                  <span v-if="$store.state.currentFilters.length > 0">{{ $tc('interface.participant_filtered') }}</span>
                  <span v-else>{{ $tc('interface.participant_count') }}</span>
                </i>
              </td>
              <td v-if="currentParticipantCount < $store.getters.reportSettings.min_results || currentParticipantCount === Infinity" class="text-center">
                <response-too-low-tooltip />
              </td>
              <td v-else class="text-center">
                {{ currentParticipantCount }}
              </td>
              <template v-for="(compare, compareIndex) in $store.state.currentCompares">
                <td
                    v-if="compares[compareIndex] && compares[compareIndex].total_count < $store.getters.reportSettings.min_results"
                    :key="questiondata.question.id + '-' + compareIndex + '-' + index"
                    class="text-center border-left">
                    <response-too-low-tooltip />
                </td>
                <td 
                  v-else-if="compares[compareIndex]"
                  :key="'n-compare-' + compareIndex"
                  class="text-center">
                  {{ compares[compareIndex].total_count }}
                </td>
              </template>
            </tr>
          </template>

        </v-data-table>
        
        <!-- When no compares are selected, we can show the open answers -->
        <v-expansion-panels flat v-if="currentComparesCount.length === 0 && questiondata && questiondata.answers && questiondata.answers.setting_other && questiondata.answers.setting_other.answers">
          <v-expansion-panel >
            <v-expansion-panel-header style="padding: 10px">
              <h2 class="text-left primary--text">
                <v-chip class="ma-2" color="primary">
                {{ questiondata.answers.setting_other.answers.filter(a => a && a.trim() !== '').length }} 
                </v-chip>
                {{ $t('report.open_antwoorden') }}
              </h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                v-if="questiondata.answers && questiondata.answers.setting_other"
                :items="questiondata.answers.setting_other.answers.filter(a => a && a.trim() !== '' && a.length > 1)"
                class="elevation-1 mb-4"
                :hide-header=true
                :items-per-page="15"
                :footer-props="{ 'items-per-page-options': [5, 15, 30, 50, -1] }">
                <template v-slot:item="{ item }">
                  <tr>
                    <td colspan=2 class="text-left text-xs" style="white-space: pre-wrap;">{{ item }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>   

        <!-- Show if no other answers are allowed -->
        <v-alert v-else-if="currentComparesCount.length === 0 && Array.isArray(questiondata.answers)" :value="true" type="info">
          {{ $t('interface.open_questions_not_allowed') }}
        </v-alert>

        <!-- We should show a warning that when comparing, no open questions can be viewed -->
        <v-alert v-else :value="true" type="info">
          {{ $t('interface.open_questions_not_supported_when_comparing') }}
        </v-alert>
        
      </div>

      <v-alert v-else :value="true" type="warning">
        {{ $t('interface.onvoldoende_respons') }}
      </v-alert>
    </v-card>
  </div>
</template>

<script>
  import { NolostInsights } from '@/mixins/NolostInsights'
  import ProgressBarSingle from '@/components/reportPageContent/ProgressBarSingle.vue'
  import ResponseTooLowTooltip from '@/components/reportPageContent/ResponseTooLowTooltip.vue'

  export default {
    name: "CustomSelect",
    mixins: [NolostInsights],
    props: ['content'],
    components: {
      ProgressBarSingle,
      ResponseTooLowTooltip
    },
    data() {
      return {
        showDetailScore: true,
        questiondata: false,
        labels: false,
        response: "-",
        loading: true,
        answers: [],
        compares: [],
        benchmarks: []
      }
    },
    computed: {
      currentComparesCount () { return this.$store.state.currentCompares },
      currentFiltersCount () { return this.$store.state.currentFilters.length || 0 },
      currentBenchmarksCount () { return this.$store.state.currentBenchmarks.length || 0 },
      currentParticipantCount () { return this.questiondata.total_count }
    },
    methods: {
      getData() {
        this.loading = true;
        this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' +
          this.content.questions.join(","))
        .then(resp => {
          this.questiondata = resp.data[0]
          this.compares = Array(this.$store.state.currentCompares.length || []) // Set the compares
          this.benchmarks = Array(this.$store.state.currentBenchmarks.length || []) // Set the compares
          this.refreshCompares() // Refresh compares
          this.sortByValue(-1)
          this.loading = false
        })
        .catch(err => {
          this.error = err
          this.loading = false
        })
      },
      sortByValue(invert = 1) {
        let tempSettingOther = ''
        if (Object.prototype.hasOwnProperty.call(this.questiondata.answers, 'setting_other')) {
          tempSettingOther = this.questiondata.answers['setting_other']
          delete this.questiondata.answers['setting_other']
        }
        this.questiondata.answers = Object.values(this.questiondata.answers).sort(function(a, b){
          return invert * (a.count - b.count)
        })

        if (tempSettingOther !== '') {
          this.questiondata.answers = { ...this.questiondata.answers }
          this.questiondata.answers['setting_other'] = tempSettingOther
        }

        this.sortCompares()
      },
      sortByName(invert = 1) {
        let tempSettingOther = ''
        if (Object.prototype.hasOwnProperty.call(this.questiondata.answers, 'setting_other')) {
          tempSettingOther = this.questiondata.answers['setting_other']
          delete this.questiondata.answers['setting_other']
        }
        this.questiondata.answers = Object.values(this.questiondata.answers).sort(function(a, b){
          return invert * (a.value.localeCompare(b.value))
        })
        if (tempSettingOther !== '') {
          this.questiondata.answers = { ...this.questiondata.answers }
          this.questiondata.answers['setting_other'] = tempSettingOther
        }

        this.sortCompares()
      },
      sortByIndex(invert = 1) {
        let tempSettingOther = ''
        if (Object.prototype.hasOwnProperty.call(this.questiondata.answers, 'setting_other')) {
          tempSettingOther = this.questiondata.answers['setting_other']
          delete this.questiondata.answers['setting_other']
        }
        this.questiondata.answers = Object.values(this.questiondata.answers).sort(function(a, b){
          return invert * parseInt(a.answers) - parseInt(b.answers)
        })

        if (tempSettingOther !== '') {
          this.questiondata.answers = { ...this.questiondata.answers }
          this.questiondata.answers['setting_other'] = tempSettingOther
        }

        this.sortCompares()
      },
      sortCompares() {
        this.compares.map(compare => {
          let sortedCompareAnswers = []
          compare.answers.forEach(compareAnswer => {
            let newCompareIndex = Object.values(this.questiondata.answers).findIndex(answer => answer.value === compareAnswer.value)
            sortedCompareAnswers[newCompareIndex] = compareAnswer
          })

          compare.answers = sortedCompareAnswers
        })
      },
      updateCompares(currentCompares, previousCompares) { 
        if (currentCompares && previousCompares && (currentCompares.length > previousCompares.length)) {
          this.addCompare(currentCompares.slice(-1)[0], currentCompares.length - 1)
        } else if ((currentCompares && previousCompares && (currentCompares.length < previousCompares.length)) ) {
          let removedCompareIndex = previousCompares.findIndex(f => !currentCompares.includes(f))
          this.removeCompare(removedCompareIndex)
        }
      },
      refreshCompares() {
        this.$store.state.currentCompares.forEach((compare, compareIndex) => {
          this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' + this.content.questions.join(",") + '&compare=' + this.$store.state.availableCompares.findIndex(c => compare.key === c.key && compare.value === c.value))
          .then(resp => { 
            resp.data[0].answers = Object.values(resp.data[0].answers)
            this.compares.splice(compareIndex, 1, {...resp.data[0], showDetail: false})
            this.sortCompares()
          })
          .catch(err => {
            this.error = err
            this.loading = false;
          })
        })
      },
      addCompare(compare, compareIndex) {
        if (compare && compare.key && compare.value) {
          // And get the data related to this compare
          this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' + this.content.questions.join(",") + '&compare=' + this.$store.state.availableCompares.findIndex(c => compare.key === c.key && compare.value === c.value))
          .then(resp => { 
            resp.data[0].answers = Object.values(resp.data[0].answers)
            this.compares.splice(compareIndex, 1, {...resp.data[0], showDetail: false})
            this.sortCompares()
          })
          .catch(err => {
            this.error = err
            this.loading = false;
          })
        }
      },
      removeCompare(compareIndex) {
        this.compares.splice(compareIndex, 1)
      },
      toggleDetailCompare(compareIndex) {
        this.compares[compareIndex].showDetail = !this.compares[compareIndex].showDetail
      },
      highlight(compare, index) {
        if (this.questiondata.answers[index] && this.questiondata.answers[index].count) {

          let percentage = this.questiondata.answers[index].count / this.questiondata.total_count
          let percentageCompare = compare.answers[index].count / compare.total_count
  
          let cutoffLow = 0.05 // item.std_dev * 0.125
          let cutoffHigh = 0.10 // item.std_dev * 0.25
  
          // // If the question is asked negatively or invert_colors is set on the table-distribution component
          if (this.questiondata.question.is_negative && this.questiondata.question.qtype.startsWith('7-point-likert') || this.content.invert_colors) {
            if (percentage - percentageCompare <= -cutoffHigh) return 'highlight_neg'
            if (percentage - percentageCompare <= -cutoffLow) return 'highlight_med_neg'
            if (percentage - percentageCompare >= cutoffHigh) return 'highlight_pos'
            if (percentage - percentageCompare >= cutoffLow) return 'highlight_med_pos'
          } else {
              // Otherwise, highlight normally
            if (percentage - percentageCompare <= -cutoffHigh) return 'highlight_pos'
            if (percentage - percentageCompare <= -cutoffLow) return 'highlight_med_pos'
            if (percentage - percentageCompare >= cutoffHigh) return 'highlight_neg'
            if (percentage - percentageCompare >= cutoffLow) return 'highlight_med_neg'
          }
        }  
        
        return 'highlight_neutral'
      },
      getHeaders() {
        let headers = [
          { text: 'Answer', sortable: true, align: 'start', value: 'item.value'},
        ]

        if (this.$store.state.currentFilters.length > 0) {
          headers.push({ text: 'Percentage (filtered)', sortable: false, align: 'center', value: 'score', width: '120px'})
        } else {
          headers.push({ text: 'Percentage', sortable: false, align: 'center', value: 'score', width: '120px'})
        }

        if (this.$store.state.currentCompares.length > 0) {
          this.$store.state.currentCompares.forEach(compare => {
            headers.push({text: compare.name, sortable: false, align: 'center', width: '120px'})
          })
        }

        return headers
      }
    },
    mounted() {
      this.getData()
    },
    watch: {
      'currentComparesCount': 'updateCompares',
      'currentFiltersCount': 'getData',
    }
  }
</script>

<style>
  .v-card__text, .v-card__title {
    word-break: normal;
  }
  .custom-select{
    margin-bottom: 20px;
  }
</style>
