var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progress-choose"},[(_vm.widths.length > 4 && _vm.item.avg)?_c('div',{staticClass:"progress-bar-slider"},[_c('div',{staticClass:"progress-bar-item",style:(("width: calc(" + ((_vm.widths[0])) + "% - 26px)"))}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"progress-bar-item progress-bar-light",style:(("width: calc(" + ((_vm.widths[1])) + "% - 26px)"))},'div',attrs,false),on))]}}],null,false,1918914607)},[_c('span',[_vm._v(" Std. Deviatie ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"progress-bar-item center",style:("width: 26px")},'div',attrs,false),on))]}}],null,false,2719373203)},[_c('span',[_vm._v(" Gemiddelde ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"progress-bar-item progress-bar-light",style:(("width: calc(" + ((_vm.widths[3])) + "% - 26px)"))},'div',attrs,false),on))]}}],null,false,2343455085)},[_c('span',[_vm._v(" Std. Deviatie ")])]),_c('div',{staticClass:"progress-bar-item",style:(("width: calc(" + ((_vm.widths[4])) + "% - 26px)"))})],1):_c('div',{staticClass:"text-center"},[_c('insufficient-data-tooltip')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }