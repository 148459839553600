<template>
    <v-tooltip top>
        <template v-slot:activator="{ on }">
            <v-icon color="orange" dark v-on="on" style="font-size: 15px;padding-bottom:5px;margin-left:5px;">info
            </v-icon>
        </template>
        <span>{{ $t('interface.onvoldoende_respons') }}</span>
    </v-tooltip>
</template>

<script>

    export default {
        name: "ResponseTooLowTooltip",
        data() {
            return {

            }
        },
        methods: {

        }
    }
</script>

<style scoped>

</style>