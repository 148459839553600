<template>
    <v-tooltip top>
        <template v-slot:activator="{ on }">
            <v-icon v-on="on" style="font-size: 15px;padding-bottom:5px;margin-left:5px;">close
            </v-icon>
        </template>
        <span>{{ $t('interface.insufficient_data') }}</span>
    </v-tooltip>
</template>

<script>
    export default {
        name: "InsufficientDataTooltip",
        props: [],
    }
</script>