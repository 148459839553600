<template>
  <v-layout row>
    <div class="col">
    <v-flex xs12>
      <v-card v-if="questiondata">
        <v-card-title class="accent white--text">
          {{ templateQuestion(questiondata.question.question || questiondata.question.name, $store.getters.reportSettings) }}
          <v-spacer/>
          <span class="text-body-2" v-if="questiondata.total_count > $store.getters.reportSettings.min_results">{{ $tc('interface.participant_count') }}: {{ questiondata.total_count }}</span>
        </v-card-title>
        <v-card-text style="padding-top: 10px">
          <div v-if="currentCompares.length > 0 || currentFilters.length > 0 || currentBenchmarks.length > 0">
              <v-alert :value="true" type="warning">
                  <!-- TODO: add to locale -->
                  Deze visualisatie ondersteund geen filters, vergelijkingen of benchmarks.
              </v-alert>
          </div>
          <v-container v-else-if="questiondata.total_count >= $store.getters.reportSettings.min_results">
            <v-row>
              <v-col cols="9">
                <v-layout align-center row v-for="(item, index) in questiondata.answers" :key="index">
                  <span xs1 :style="getStyle(index)">&nbsp;</span>
                  <v-flex xs7>{{ item.value }}</v-flex>
                  <v-flex xs3>
                    <v-progress-linear
                        :color="getNolostColor(index) || '#E5E5E5'"
                        height="5"
                        :value="item.average || item.count"
                    ></v-progress-linear>
                  </v-flex>
                  <v-flex xs1 font-weight-light style="padding-left: 4px;">
                    {{ Math.round(item.average || item.count) | dutch_number }}%
                  </v-flex>
                </v-layout>
              </v-col>
              <v-col cols="3">
                <doughnut-chart v-if="datacollection" :chart-data="datacollection" :options="chartOptions"></doughnut-chart>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-else>
            <v-alert :value="true" type="warning">
              {{ $t('interface.onvoldoende_respons') }}
            </v-alert>
          </v-container>
        </v-card-text>
      </v-card>
    </v-flex>
    </div>
  </v-layout>
</template>

<script>
  import {NolostInsights} from '@/mixins/NolostInsights'
  import DoughnutChart from '@/plugins/charts/DoughnutChart.js'

  export default {
    name: "CustomDivideHundred",
    components: {
      DoughnutChart,
    },
    mixins: [NolostInsights],
    props: ['content'],
    data() {
      return {
        questiondata: false,
        labels: false,
        response: "-",
        loading: true,
        datacollection: null,
        chartOptions: {legend: {display: false},}
      }
    },
    computed: {
        currentCompares () { return this.$store.state.currentCompares },
        currentFilters () { return this.$store.state.currentFilters },
        currentBenchmarks () { return this.$store.state.currentBenchmarks },
    },
    methods: {
      getData() {
        this.loading = true;

        this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' +
          this.content.questions.join(","))
        .then(resp => {
          this.questiondata = resp.data[0]
          this.setChartData()
          this.loading = false
        })
        .catch(err => {
          this.error = err
          this.loading = false
        })
      },
      setChartData() {
        let colors = []
        let hoverColors = []
        for (let i = 0; i < Object.keys(this.questiondata.answers).length; i++) {
          colors.push(this.getNolostColor(i));
          hoverColors.push(this.getNolostColor(i, true));
        }

        // Sort high to low 
        this.questiondata.answers = Object.values(this.questiondata.answers).sort(function(a, b){
          return (a.average || a.count) > (b.average || b.count) ? -1 : 1
        })
        
        this.datacollection = {
          datasets: [{
            data: this.questiondata.answers.map(a => a.average || a.count),
            hoverBackgroundColor: hoverColors,
            backgroundColor: colors,
          }],
          labels: this.questiondata.answers.map(a => a.value),
          options: {
            legend: {position: 'bottom'},
          }
        }
      },
      getStyle(index) {
        let bgColor = this.getNolostColor(index) || "#E5E5E5"
        return "padding: 0 10px; margin-right: 5px; height: 20px; background-color: " +  bgColor
      }
    },
    mounted() {
      this.getData()
    }
  }
</script>

<style>
  .layout.align-center.row{
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .layout.align-center.row:last-child{
    border: 0px
  }
</style>
