<template>
  <div>
    <div v-for="(questiondata, index) in questions" :key="index">
      <v-card >
        <v-card-title class="accent white--text">
          {{ questiondata.question.name }}
          <v-spacer />
          <span class="text-body-2" v-if="questiondata.total_count > $store.getters.reportSettings.min_results">response: {{ questiondata.total_count }}</span>
        </v-card-title>
        <v-card-text>
          <!-- <blockquote v-if="questiondata.question.name != questiondata.question.question">
            {{ questiondata.question.question }}
          </blockquote> -->
          <v-container v-if="questiondata.total_count >= $store.getters.reportSettings.min_results || false" fill-height>
            <v-row align="center" justify="center">
              <v-col md="6" cols="12" class="text-center font-weight-light">
                <div style="max-width: 325px">
                  <doughnut-chart :chart-data="getChartData(index)" :options="chartOptions"></doughnut-chart>
                </div>
              </v-col>
              <v-col md="6" cols="12" class="text-center font-weight-light">
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Antwoord
                        </th>
                        <th class="text-center">
                          Aantal
                        </th>
                        <th class="text-center">
                          %
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        :class="getColourClass('yes', questiondata.question)"
                      >
                        <th>Ja</th>
                        <td>{{ questiondata.answers.yes | dutch_number }}</td>
                        <td>{{ (questiondata.answers.yes / questiondata.total_count * 100).toFixed(1) | dutch_number }}%</td>
                      </tr>
                      <tr
                        :class="getColourClass('no', questiondata.question)"
                      >
                        <th>Nee</th>
                        <td>{{ questiondata.answers.no | dutch_number }}</td>
                        <td>{{ (questiondata.answers.no / questiondata.total_count * 100).toFixed(1) | dutch_number }}%</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-else>
            <v-alert :value="true" type="warning">
              {{ $t('interface.onvoldoende_respons') }}
            </v-alert>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
  import { NolostInsights } from '@/mixins/NolostInsights'
  import DoughnutChart from '@/plugins/charts/DoughnutChart.js'

  export default {
    name: "YesNo",
    mixins: [NolostInsights],
    props: ['content'],
    components: {
      DoughnutChart
    },
    data () {
      return {
        questions: false,
        labels: false,
        response: "-",
        loading: true,
        datacollection: null,
        chartOptions: {legend: {position: 'left'},}
      }
    },
    methods: {
      negative_display (question) {
        if (question.qtype.startsWith("grade-1-10")) {
          return !question.is_negative
        }
        else
          return question.is_negative
      },
      getData () {
        this.loading = true;

        this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' +
                this.content.questions.join(","))
          .then(resp => {
            this.questions = []
            for (let i = 0; i < resp.data.length; i++) {
              this.questions[i] = resp.data[i]
            }
            console.log(this.questions)
            this.loading = false
          })
          .catch(err => {
            this.error = err
            this.loading = false
          })
      },
      getChartData(index){
        let question = this.questions[index]
        let labels = ["Ja", "Nee"]

        if (question.question.is_negative) {
          labels = ["Nee", "Ja"]
        }
        return {
          datasets: [{
            data: [
              this.getPositive(question, true),
              this.getNegative(question, true),
            ],
            backgroundColor: ["#00ac85", "#e4003a"],
            hoverBackgroundColor: ["#00ac85", "#e4003a"],
          }],
          labels: labels,
          options: {
            legend: {position: 'bottom'},
          }
        }
      },
    },
    mounted() {
      this.getData()
    }
  }
</script>

<style scoped>

</style>
