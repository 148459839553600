<template>
    <v-tooltip top>
        <template v-slot:activator="{ on }">
            <v-icon color="orange" dark v-on="on" style="font-size: 15px;padding-bottom:5px;margin-left:5px;">info
            </v-icon>
        </template>
        <span>{{ $t(getMessage()) }}</span>
    </v-tooltip>
</template>

<script>

    export default {
        name: "NegativeQuestionTooltip",
        props: [
            'questiondata',
            'show_score'
        ],
        data() {
            return {
                question: this.questiondata,
                score: this.show_score || false,
            }
        },
        methods: {
            getMessage () {
                if (this.score) {
                    if (this.question.qtype.includes('frequency')){
                        return 'interface.negative_question_with_score'
                    } else {
                        return 'interface.negative_question_recoded_with_score'
                    }
                } else {
                    if (this.question.qtype.includes('frequency')){
                        return 'interface.negative_question'
                    } else {
                        return 'interface.negative_question_recoded'
                    }
                }
                
            }
        }
    }
</script>
