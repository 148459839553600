<template>
  <div v-if="content.boxed">
      <v-card>
        <v-card-title :class="getTitleBoxColor()" v-if="content.title">
          {{ content.title }}
        </v-card-title>
        <v-card-text v-if="content.text" v-html="content.text" class="blueshade pt-6"/>
      </v-card>
  </div>
  <div v-else>
    <h2 v-if="content.title" v-html="content.title" class="font-weight-light mb-1"></h2>
    <div v-if="content.text" v-html="content.text"></div>
  </div>
</template>


<script>
  export default {
    name: "TextContent",
    props: ['content'],
    methods: {
      getTitleBoxColor() {
        if (this.content.color !== undefined && this.content.color == "light")
          return "accent white--text"
        else
          return "primary white--text"
      }
    }
  }
</script>

