<template>

    <div v-html="$t(content.text)"></div>

</template>

<script>
  export default {
    name: "TranslationContent",
    props: ['content'],
  }
</script>

