<template>
  <div>
    <div v-if="loading" class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else-if="error" class="text-center">
      {{ error }}
    </div>
    <div v-else>
      <v-data-table
        :headers="getHeaders()"
        :items="this.questions"
        :calculate-widths="true"
        :custom-sort="customSort"
        item-key="index"
        hide-default-footer
        disable-pagination
        class="elevation-1 mb-4">
        <template v-slot:header="{ getHeaders }">
          {{ getHeaders }}
        </template>

        <template v-slot:item="{ item, index }">  
          <tr>
            <template>
              <td style="min-width: 300px;">
                <span v-if="item.question.question">{{ templateQuestion(item.question.question, $store.getters.reportSettings) }}</span>
                <span v-else>{{ item.question.name }}</span>
                <negative-question-tooltip :questiondata="item.question" :show_score="content.show_score || false" v-if="item.question.is_negative" />
              </td>
            </template>
            <template v-if="loadingFilter">
              <td class="text-center border-left">
                <v-progress-circular
                  :size="20"
                  :width="2"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </td>
            </template>
            <template v-else-if="item.score && item.total_count >= $store.getters.reportSettings.min_results">
              <td 
                :class="{'show-details-score' : showDetailScore}"
                class="text-center border-left"
                style="cursor: pointer;"
                @click="showDetailScore = !showDetailScore">
                <v-chip v-if="content.show_score" class="score">
                  {{ item.score.toFixed(1) | dutch_number }}
                </v-chip>
                <v-chip v-else-if="item.counts_i" class="score">
                  <span v-if="content.show_negative">
                    {{ parseFloat(item.perc_neg * 100).toFixed(0) }}%
                  </span>
                  <span v-else>
                    {{ parseFloat(item.perc_pos * 100).toFixed(0) }}%
                  </span>
                </v-chip>
                <div class="float-end">
                  <progress-bar v-if="showDetailScore" :item="item" :negative="content.show_negative || false"/>
                </div>
              </td>
            </template>
            <template v-else>
              <td class="text-center border-left"><response-too-low-tooltip /></td>
            </template>
            <template v-for="(compare, compareIndex) in $store.state.currentCompares">
              <!-- Show a compare -->
              <td
                v-if="item.compares[compareIndex] && item.compares[compareIndex].total_count && item.compares[compareIndex].total_count < $store.getters.reportSettings.min_results"
                :key="item.question.id + '-' + compareIndex + '-' + index"
                class="text-center border-left">
                <response-too-low-tooltip />
              </td>
              <td
                v-else-if="item.compares[compareIndex] && !item.compares[compareIndex].counts_i && !content.show_score"
                :key="item.question.id + '-' + compareIndex + '-' + index"
                class="text-center border-left">
                <response-too-low-tooltip />
              </td>
              <td 
                v-else-if="item.compares[compareIndex] && item.compares[compareIndex].score" 
                :key="item.question.id + '-' + compareIndex + '-' + index" 
                :class="{'show-details-compare' : item.compares[compareIndex].showDetail}"
                class="text-center border-left"
                style="cursor: pointer;"
                @click="toggleDetailCompare(compareIndex)">
                <v-chip v-if="content.show_score"
                  :class="highlight(item.compares[compareIndex], item)"
                  class="score compare-score">
                  {{ item.compares[compareIndex].score.toFixed(1) | dutch_number }}
                </v-chip>
                <v-chip v-else-if="item.compares[compareIndex].counts_i && !content.show_score"
                  :class="highlight(item.compares[compareIndex], item)"
                  class="score compare-score">
                  <span v-if="content.show_negative">
                    {{ parseFloat(item.compares[compareIndex].perc_neg * 100).toFixed(0) }}%
                  </span>
                  <span v-else>
                    {{ parseFloat(item.compares[compareIndex].perc_pos * 100).toFixed(0) }}%
                  </span>
                </v-chip>
                <progress-bar v-if="item.compares[compareIndex].showDetail" :item="item.compares[compareIndex]" :negative="content.show_negative || false"/>
              </td>
              <td 
                v-else-if="item.compares[compareIndex] && item.compares[compareIndex].score === false" 
                :key="item.question.id + '-' + compareIndex + '-' + index" 
                class="text-center border-left">
                <response-too-low-tooltip />
              </td>
              <!-- Show loading -->
              <td 
                v-else
                class="text-center border-left"
                :key="item.question.id + '-' + compareIndex + '-' + index">
                <v-progress-circular
                  :size="20"
                  :width="2"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </td>
              <!-- Show the response to low -->
            </template>
            <template v-for="(benchmark, benchmarkIndex) in $store.state.currentBenchmarks">
              <td 
                v-if="item.benchmarks[benchmark.id]"
                class="benchmark text-center" 
                :key="'benchmark-value-' + benchmarkIndex"
                :class="{'show-details-benchmark' : item.benchmarks[benchmark.id].showDetail || false }"
                style="cursor: pointer;"
                @click="toggleDetailBenchmark(benchmark.id)"
                >
                <question-benchmark-project v-if="benchmark.type === 'benchmark_project'"
                  :item="item" 
                  :benchmark="benchmark" 
                  :invert_colors="content.invert_colors || false"
                  :show_score="content.show_score || false"
                  :show_negative="content.show_negative || false"
                  />
                <question-benchmark v-else
                  :item="item" 
                  :benchmark="benchmark" 
                  :invert_colors="content.invert_colors || false"
                  :show_score="content.show_score || false"
                  :show_negative="content.show_negative || false"
                  />
                <progress-bar v-if="item.benchmarks[benchmark.id].showDetail" :item="item.benchmarks[benchmark.id]" :negative="content.show_negative || false"/>
              </td>
              <td v-else :key="'benchmark-value-else-' + benchmarkIndex" class="benchmark text-center">
                <insufficient-data-tooltip />
              </td>
            </template>
          </tr>
        </template>

        <template v-slot:[`body.append`]>
          <tr class="table-bottom">
            <td>
              <i>
                <span v-if="$store.state.currentFilters.length > 0">{{ $tc('interface.participant_filtered') }}</span>
                <span v-else>{{ $tc('interface.participant_count') }}</span>
              </i>
            </td>
            <td v-if="loadingFilter" class="text-center">
              <v-progress-circular
                :size="20"
                :width="2"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </td>
            <td v-else-if="currentParticipantCount < $store.getters.reportSettings.min_results || currentParticipantCount === Infinity" class="text-center">
              <response-too-low-tooltip />
            </td>
            <td v-else class="text-center">
              {{ currentParticipantCount }}
            </td>
            <template v-for="(compare, compareIndex) in $store.state.currentCompares">
              <td v-if="loadingFilter" class="text-center" :key="'compare-' + compareIndex" >
                <v-progress-circular
                  :size="20"
                  :width="2"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </td>
              <td 
                v-else-if="questions.filter(q => q.compares[compareIndex]).map(q => q.compares[compareIndex]).length > 0"
                :key="'compare-else-if' + compareIndex" 
                class="text-center">
                <span v-if="Math.min(...questions.filter(q => q.compares[compareIndex]).map(q => q.compares[compareIndex].total_count)) < $store.getters.reportSettings.min_results">
                  <response-too-low-tooltip />
                </span>
                <span v-else>
                  {{ Math.min(...questions.filter(q => q.compares[compareIndex]).map(q => q.compares[compareIndex].total_count)) }}
                </span>
              </td>
              <td 
                v-else
                class="text-center"
                :key="'compare-else-' + compareIndex">
                <v-progress-circular
                  :size="20"
                  :width="2"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </td>
            </template>
            
            <template v-for="(benchmark, benchmarkIndex) in $store.state.currentBenchmarks">
              <td v-if="benchmark.type !== 'benchmark_project'" class="benchmark-bottom text-center" :key="'benchmark_' + benchmarkIndex">
                -
              </td>
              <td v-else-if="Math.min(...questions.filter(q => q.benchmarks[benchmark.id] && q.benchmarks[benchmark.id].total_count).map(q => q.benchmarks[benchmark.id].total_count)) === Infinity" class="text-center benchmark-bottom" :key="'benchmark_' + benchmarkIndex">
                -
              </td>
              <td v-else-if="benchmark.type === 'benchmark_project'" class="benchmark-bottom text-center" :key="'benchmark_else_if' + benchmarkIndex">
                <span v-if="Math.min(...questions.filter(q => q.benchmarks[benchmark.id] && q.benchmarks[benchmark.id].total_count).map(q => q.benchmarks[benchmark.id].total_count)) < $store.getters.reportSettings.min_results">
                  <response-too-low-tooltip />
                </span>
                <span v-else>
                  {{ Math.min(...questions.filter(q => q.benchmarks[benchmark.id] && q.benchmarks[benchmark.id].total_count).map(q => q.benchmarks[benchmark.id].total_count)) }}
                </span>
              </td>
              <!-- If it isn't a benchmark_project, we cannot provide a response count -->
              <td v-else class="benchmark-bottom text-center" :key="'benchmark_else_' + benchmarkIndex">
                <insufficient-data-tooltip />
              </td>
            </template>
          </tr>
        </template>

        <template v-slot:no-data>
          <tr>
            <td colspan="3">
              Loading data
            </td>
          </tr>
        </template>

        <template v-slot:no-results>
          <tr class="red lighten-1">
            <td colspan="3">
              Unable to load data..
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    
  </div>
</template>

<script>
import { NolostInsights } from '@/mixins/NolostInsights'
import QuestionBenchmark from '@/components/reportPageContent/QuestionBenchmark.vue'
import QuestionBenchmarkProject from '@/components/reportPageContent/QuestionBenchmarkProject.vue'
import NegativeQuestionTooltip from '@/components/reportPageContent/NegativeQuestionTooltip.vue'
import InsufficientDataTooltip from '@/components/reportPageContent/InsufficientDataTooltip.vue'
import ResponseTooLowTooltip from '@/components/reportPageContent/ResponseTooLowTooltip.vue'
import ProgressBar from '@/components/reportPageContent/ProgressBar.vue'

export default {
  name: "TableDistribution",
  mixins: [NolostInsights],
  props: ['content'],
  components: {
    NegativeQuestionTooltip,
    ResponseTooLowTooltip,
    InsufficientDataTooltip,
    QuestionBenchmark,
    QuestionBenchmarkProject,
    ProgressBar,
  },
  data () {
    return {
      loading: true,
      loadingFilter: false,
      questions: [],
      valueType: 'percentage',
      error: undefined,
      cancel: undefined,
      showDetailCompare: undefined,
      showDetailScore: false,
      hover: false,
    }
  },
  computed: {
    currentComparesCount () { return this.$store.state.currentCompares },
    currentFiltersCount () { return this.$store.state.currentFilters },
    currentBenchmarksCount () { return this.$store.state.currentBenchmarks },
    currentParticipantCount () { return Math.min(...this.questions.filter(q => q && q.total_count).map(q => q.total_count)) }
  },
  methods: {
    getData () {
      this.loading = true;
      this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' +
        this.content.questions.join(","))
      .then(resp => {
        this.questions = resp.data.map(item => {
          item.compares = Array(this.$store.state.currentCompares.length || []) // Set the compares
          item = this.getIndexedCounts(item) // Get the index counts
          item.labels = this.getLabels(item.question.qtype) // Get the labels for each index
          return item
        })
        this.refreshCompares()
        this.loading = false;
      })
      .catch(err => {
        this.error = err      
        this.loading = false;
      })
    },
    updateCompares(currentCompares, previousCompares) { 
      if (currentCompares && previousCompares && (currentCompares.length > previousCompares.length)) {
        this.addCompare(currentCompares.slice(-1)[0], currentCompares.length - 1)
      } else if ((currentCompares && previousCompares && (currentCompares.length < previousCompares.length)) ) {
        let removedCompareIndex = previousCompares.findIndex(f => !currentCompares.includes(f))
        this.removeCompare(removedCompareIndex)
      } 
    },
    refreshCompares() {
      this.$store.state.currentCompares.forEach((compare, compareIndex) => {
        this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' + this.content.questions.join(",") + '&compare=' + this.$store.state.availableCompares.findIndex(c => compare.name === c.name))
        .then(resp => { 
          resp.data.forEach(item => {
            item = this.getIndexedCounts(item) // Get the index counts
            item.labels = this.getLabels(item.question.qtype) // Get the labels for each index
            item.showDetail = false // We start out collapsed

            let questionIndex = this.questions.findIndex(question => { return question.question.id === item.question.id})
            if (questionIndex >= 0) {
              this.questions[questionIndex].compares.splice(compareIndex, 1, item)
            }
          })
        })
        .catch(err => {
          this.error = err
          this.loading = false;
        })
      })
    },
    addCompare(compare, compareIndex) {
      if (compare && compare.key && compare.value) {
        // And get the data related to this compare
        this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' + this.content.questions.join(",") + '&compare=' + this.$store.state.availableCompares.findIndex(c => compare.name === c.name))
        .then(resp => { 
          resp.data.forEach(item => {
            item = this.getIndexedCounts(item) // Get the index counts
            item.labels = this.getLabels(item.question.qtype) // Get the labels for each index
            item.showDetail = false // We start out collapsed

            let questionIndex = this.questions.findIndex(question => { return question.question.id === item.question.id})
            if (questionIndex >= 0) {
              this.questions[questionIndex].compares.splice(compareIndex, 1, item)
            }
          })
        })
        .catch(err => {
          this.error = err
          this.loading = false;
        })
      }
    },
    removeCompare(compareIndex) {
      this.questions.forEach(question => {
        question.compares.splice(compareIndex, 1)
      })
    },
    updateFilters() {
      this.loadingFilter = true
      let oldData = this.questions
      
      // Cancel the pending request
      if (this.cancel) { this.cancel.abort() }

      // Create a new cancel controller
      this.cancel = new AbortController();

      // Make the query
      this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' +
        this.content.questions.join(","), {signal: this.cancel.signal})
      .then(resp => {  

        resp.data.forEach((item, index) => {
          item = this.getIndexedCounts(item) // Get the index counts
          item.labels = this.getLabels(item.question.qtype) // Get the labels for each index
          item.compares = oldData[index].compares // Get the old compares
        })
        this.questions = resp.data
        this.refreshCompares() // Refresh compares
        this.loadingFilter = false;
      })
      .catch(err => {
        this.error = err
        this.loading = false;
      })

      // Make the query again for each compare_report listed (and including the filters...)
    },
    updateBenchmarks() {
      this.getData()
    },
    getHeaders() {
      let headers = [
        { text: 'Question', sortable: true, align: 'start', value: 'question.name'},
      ]

      if (this.$store.state.currentFilters.length > 0) {
        headers.push({ text: 'Score (filtered)', align: 'center', value: 'score', width: '120px'})
      } else {
        headers.push({ text: 'Score', align: 'center', value: 'score', width: '120px'})
      }

      if (this.$store.state.currentCompares.length > 0) {
        this.$store.state.currentCompares.forEach(compare => {
          headers.push({text: compare.name, sortable: false, align: 'center', width: '120px'})
        })
      }
      
      if (this.$store.state.currentBenchmarks) { 
        this.$store.state.currentBenchmarks.forEach(benchmark => {
          if (benchmark.type == 'benchmark_project') {
            headers.push({text: 'Project: ' + benchmark.name, sortable: false, align: 'center', class: "benchmark", width: '120px'})
          } else {
            headers.push({text: 'Benchmark: ' +benchmark.name, sortable: false, align: 'center', class: "benchmark", width: '120px'})
          }
        })
      }

      return headers
    },
    highlight(compareItem, item) {
      
      // When showing a score, we need different cuttoffs and calculations
      if (this.content.show_score) {
        let cutoffLow = 0.5 // item.std_dev * 0.125
        let cutoffHigh = 1 // item.std_dev * 0.25
        
        // If the question is asked negatively or invert_colors is set on the table-distribution component
        if (item.question.is_negative && item.question.qtype.startsWith("7-point-likert") || this.content.invert_colors) {
          if (item.score - compareItem.score <= -cutoffHigh) return 'highlight_neg'
          if (item.score - compareItem.score <= -cutoffLow) return 'highlight_med_neg'
          if (item.score - compareItem.score >= cutoffHigh) return 'highlight_pos'
          if (item.score - compareItem.score >= cutoffLow) return 'highlight_med_pos'
        } else {
          // Otherwise, highlight normally
          if (item.score - compareItem.score <= -cutoffHigh) return 'highlight_pos'
          if (item.score - compareItem.score <= -cutoffLow) return 'highlight_med_pos'
          if (item.score - compareItem.score >= cutoffHigh) return 'highlight_neg'
          if (item.score - compareItem.score >= cutoffLow) return 'highlight_med_neg'
        }
      // Otherwise, we use the percentages
      } else {
        let cutoffLow = 0.05 // item.std_dev * 0.125
        let cutoffHigh = 0.10 // item.std_dev * 0.25

        // If the question is asked negatively or invert_colors is set on the table-distribution component
        if (item.question.is_negative && item.question.qtype.startsWith("7-point-likert") || this.content.invert_colors) {
          if (item.perc_neg - compareItem.perc_neg <= -cutoffHigh) return 'highlight_neg'
          if (item.perc_neg - compareItem.perc_neg <= -cutoffLow) return 'highlight_med_neg'
          if (item.perc_neg - compareItem.perc_neg >= cutoffHigh) return 'highlight_pos'
          if (item.perc_neg - compareItem.perc_neg >= cutoffLow) return 'highlight_med_pos'
        } else {
          // Otherwise, highlight normally
          if (item.perc_pos - compareItem.perc_pos <= -cutoffHigh) return 'highlight_pos'
          if (item.perc_pos - compareItem.perc_pos <= -cutoffLow) return 'highlight_med_pos'
          if (item.perc_pos - compareItem.perc_pos >= cutoffHigh) return 'highlight_neg'
          if (item.perc_pos - compareItem.perc_pos >= cutoffLow) return 'highlight_med_neg'
        }
      }

      return 'highlight_neutral'
    },
    toggleDetailCompare(compareIndex) {
      if (!this.content.disable_show_details) {
        this.questions.forEach(question => {
          question.compares[compareIndex].showDetail = !question.compares[compareIndex].showDetail
        })
      }
    },
    toggleDetailBenchmark(benchmarkId) {
      if (!this.content.disable_show_details) {
        this.questions.forEach((_, questionIndex) => {
          console.log('hello')
          let question = this.questions[questionIndex]
          // console.log(question.benchmarks[benchmarkId].showDetail)
          if (question.benchmarks[benchmarkId] && question.benchmarks[benchmarkId].showDetail) { 
            question.benchmarks[benchmarkId].showDetail = !question.benchmarks[benchmarkId].showDetail
          } else if (question.benchmarks[benchmarkId]) {
            question.benchmarks[benchmarkId].showDetail = true
          }
          this.questions.splice(questionIndex, 1, question)
          
        })
      }
    },
    customSort(items, index, isDesc) {
      // Initial sort based on the index of the questions or if no sort is selected
      if (isDesc.length === 0) { 
        items.sort((a, b) => {
          return a.index < b.index ? -1 : 1
        })
      } else {
        // If show_score is set we should sort on value 

        if (this.content.show_score) {
          items.sort((a, b) => {
            if (isDesc != "false") {
              return a.score < b.score ? -1 : 1
            } else {
              return b.score < a.score ? -1 : 1
            }
          })

        } else {
          // If all the questions are asked in as a negative question, we should sort on the negative percentage
          if (items.every(item => item.question.is_negative)) {
            items.sort((a, b) => {
              if (isDesc != "false") {
                return a.perc_neg < b.perc_neg ? -1 : 1
              } else {
                return b.perc_neg < a.perc_neg ? -1 : 1
              }
            })
          // Otherwise, we just sort on the positive percentage
          } else {
            items.sort((a, b) => {
              if (isDesc != "false") {
                return a.perc_pos < b.perc_pos ? -1 : 1
              } else {
                return b.perc_pos < a.perc_pos ? -1 : 1
              }
            })
          }
        }
      }
      
      return items
    }
  },
  filters: {
    getValue: function(arr, type, count, totalCount) {
      if (type === 'count') {
        let item = arr.filter(item => item.value === count)
        if (item.length !== 0) {
          return item[0].count
        } 
        return 0
      } else if (type === 'percentage') {
        if (totalCount === 0) {
          return "0%"
        }
        let item = arr.filter(item => item.value === count)
        if (item.length !== 0) {
          return ((item[0].count / totalCount) * 100).toFixed(0) + "%"
        }
        return "0%"
      }
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    'currentBenchmarksCount': 'updateBenchmarks',
    'currentComparesCount': 'updateCompares',
    'currentFiltersCount': 'getData',
  }
}
</script>
