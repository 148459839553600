<template>
  <div class="progress-bar-single" style="max-width: 200px;">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div 
          :style="`width: ${(percentage)}%`" 
          v-bind="attrs"
          v-on="on"
          class="progress-bar-item primary">
        </div>
      </template>
      <span>
        {{percentage}}%
      </span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div 
          :style="`width: ${(100 - percentage)}%`" 
          v-bind="attrs"
          v-on="on"
          class="progress-bar-item progress-bar-light">
        </div>
      </template>
      <span>
        {{(100 - percentage)}}%
      </span>
    </v-tooltip>
  </div>

</template>

<script>
import { NolostInsights } from '@/mixins/NolostInsights'

export default {
  name: "ProgressbarSingle",
  props: ['percentage'],
  mixins: [NolostInsights],
}    
</script>


<style scoped>
  .progress-bar-single{
    height: 15px;
    background-color: #E5F5FB;
    overflow: hidden;
    border-radius: 8px;
    margin-top: 8px;
  }
  
  .progress-bar-item{
    float: left;
    height: 15px;
    display: inline-block;
    box-sizing: border-box;
  }
</style>