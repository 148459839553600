<template>
  <v-card class="flex d-flex flex-column component-single-score" :key="content.title">
    <v-card-title class="accent white--text flex" v-if="content.title">
      {{ content.title }}
      <v-spacer />
      <span class="text-body-2" v-if="this.response > $store.getters.reportSettings.min_results">
        {{ $tc('interface.participant_count') }}: {{ this.response }}
      </span>
    </v-card-title>
    <v-card-text v-if="this.loading" class="text-center">
      <v-flex class="text-center text-h1 font-weight-light pt-5">
        <span>
          <v-progress-circular indeterminate color="primary" :size="200" :width="20"></v-progress-circular>
        </span>
      </v-flex>
    </v-card-text>
    <v-card-text v-else-if="this.response < $store.getters.reportSettings.min_results">
      <v-alert :value="true" type="warning">
        {{ $t('interface.onvoldoende_respons') }}
      </v-alert>
    </v-card-text>
    <v-card-text v-else>
      <v-flex class="text-center font-weight-light pt-5" :key="makeKey()">
        <span class="text-h1">
          <v-progress-circular
            :rotate="90"
            :size="200"
            :width="20"
            :value="this.percentage"
            :color="getRedGreenColor(this.percentage / 100)">
            {{ this.percentage }}%
          </v-progress-circular>
        </span>

        <!-- Show compares -->
        <template v-if="$store.state.currentCompares.length > 0">
          <div v-for="compare in compares" :key="'compare-' + compare.id" class="mt-3 mx-2 text-center">
            <div class="text-center">
              {{ compare.name }}: {{ compare.percentage }}%
              <v-icon v-if="parseInt(compare.percentage) > parseInt(percentage)" color="green">mdi-chevron-up</v-icon>
              <v-icon v-else-if="parseInt(compare.percentage) < parseInt(percentage)" color="red">mdi-chevron-down</v-icon>
              <v-icon v-else color="blue">mdi-minus</v-icon>
            </div>
          </div>
        </template>
        
        <!-- Only show a line if both benchmarks and compares are set  -->
        <hr style="margin-top: 10px" v-if="$store.state.currentBenchmarks.length > 0 && $store.state.currentCompares.length > 0"/>

        <!-- Show benchmarks -->
        <template v-if="$store.state.currentBenchmarks.length > 0">
          <div v-for="benchmark in benchmarks" :key="'benchmark-' + benchmark.id"
            class="mt-3 mx-2 text-center">
            <div class="text-center">
              <span v-if="benchmark.type == 'benchmark_project'"><strong>Project: </strong> {{ benchmark.name }}</span>
              <span v-else><strong>Benchmark:</strong> {{ benchmark.name }}</span>

              <span v-if="benchmark.score && benchmark.percentage">
                : {{ benchmark.percentage}}%
                <v-icon v-if="parseInt(benchmark.percentage) > parseInt(percentage)" color="green">mdi-chevron-up</v-icon>
                <v-icon v-else-if="parseInt(benchmark.percentage) < parseInt(percentage)" color="red">mdi-chevron-down</v-icon>
                <v-icon v-else color="blue">mdi-minus</v-icon>
              </span>

              <span v-else>
                <response-too-low-tooltip/>
              </span>
            </div>
          </div>
        </template>
      </v-flex>
    </v-card-text>
    
  </v-card>
</template>

<script>
  import ResponseTooLowTooltip from '@/components/reportPageContent/ResponseTooLowTooltip.vue'
  import { NolostInsights } from '@/mixins/NolostInsights'
  import { v4 as uuidv4 } from 'uuid';

  export default {
    name: "SingleScore",
    mixins: [NolostInsights],
    props: ['content'],
    components: {
      ResponseTooLowTooltip
    },
    data () {
      return {
        data: null,
        score: "-",
        response: "-",
        loading: true,
        compares: [],
        benchmarks: [],
        percentage: "-",
      }
    },
    computed: {
      currentFiltersCount () { return this.$store.state.currentFilters },
      currentComparesCount () { return this.$store.state.currentCompares },
      currentBenchmarksCount () { return this.$store.state.currentBenchmarks },
    },
    methods: {
      getData () {
        this.loading = true;
        let url = process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' + this.content.questions.join(",")
        
        // If we need to invert the scores
        if (this.content.invert) (
          url = url + "&invert=true"
        )
        
        this.axios.get(url)
          .then(resp => {
            let question_count = resp.data.length
            let sum_scores = resp.data.map(i => i.score).reduce((a, b) => a + b, 0)
            let positive_count = resp.data.map(i => i.count_pos).reduce((a, b) => a + b, 0)
            let response_count = resp.data.map(i => i.total_count).reduce((a, b) => a + b, 0)
            let response = resp.data.map(i => i.total_count).reduce((a, b) => a + b, 0)
            this.data = resp.data 
            this.score = (sum_scores / question_count).toFixed(1);
            this.percentage = ((positive_count / response_count) * 100 ).toFixed(0);
            this.response = Math.round(response / question_count);
            this.initCompares()
            this.initBenchmarks()
            this.loading = false;
          })
          .catch(err => {
            this.error = err
            this.loading = false;
          })
      },
      makeKey() {
        return uuidv4()
      },
      updateCompares(currentCompares, previousCompares) { 
        if (currentCompares && previousCompares && (currentCompares.length > previousCompares.length)) {
          this.addCompare(currentCompares.slice(-1)[0], currentCompares.length - 1)
        } else if ((currentCompares && previousCompares && (currentCompares.length < previousCompares.length)) ) {
          let removedCompareIndex = previousCompares.findIndex(f => !currentCompares.includes(f))
          this.removeCompare(removedCompareIndex)
        } 
      },
      updateBenchmarks(currentBenchmarks, previousBenchmarks) { 
        if (currentBenchmarks && previousBenchmarks && (currentBenchmarks.length > previousBenchmarks.length)) {
          this.addBenchmark(currentBenchmarks.slice(-1)[0], currentBenchmarks.length - 1)
        } else if ((currentBenchmarks && previousBenchmarks && (currentBenchmarks.length < previousBenchmarks.length)) ) {
          let removedBenchmarkIndex = previousBenchmarks.findIndex(f => !currentBenchmarks.includes(f))
          this.removeBenchmark(removedBenchmarkIndex)
        } 
      },
      initCompares() {
        this.$store.state.currentCompares.forEach((compare, compareIndex) => {
          this.addCompare(compare, compareIndex)
        })
      },
      initBenchmarks() {
        this.$store.state.currentBenchmarks.forEach((benchmark, benchmarkIndex) => {
          this.addBenchmark(benchmark, benchmarkIndex)
        })
      },
      addBenchmark(benchmark, benchmarkIndex) {
        try {
          if (benchmark.type === 'benchmark_project') {
            let benchmarkData = this.data.map(i => i.benchmarks[benchmark.id])
            let benchmark_question_count = benchmarkData.length
            let benchmark_sum_scores = benchmarkData.map(i => i.score || 0).reduce((a, b) => a + b, 0)
            let benchmark_positive_count = benchmarkData.map(i => i.count_pos).reduce((a, b) => a + b, 0)
            let benchmark_response_count = benchmarkData.map(i => i.total_count).reduce((a, b) => a + b, 0)
            benchmarkData = {...benchmarkData, ...benchmark}
            benchmarkData.score = (benchmark_sum_scores / benchmark_question_count).toFixed(1);
            benchmarkData.percentage = ((benchmark_positive_count / benchmark_response_count) * 100 ).toFixed(0);
            benchmarkData.response = Math.round(benchmark_response_count / benchmark_question_count);
            this.benchmarks = [benchmarkData, ...this.benchmarks]
          } else {
            let benchmarkData = this.data.map(i => i.benchmarks[benchmark.id])
            let benchmark_question_count = benchmarkData.length
            let benchmark_sum_scores = benchmarkData.map(i => i.score || 0).reduce((a, b) => a + b, 0)
            let benchmark_sum_perc_pos = benchmarkData.map(i => i.perc_pos).reduce((a, b) => a + b, 0)
            benchmarkData = {...benchmarkData, ...benchmark}
    
            benchmarkData.score = (benchmark_sum_scores / benchmark_question_count).toFixed(1);
            benchmarkData.percentage = ((benchmark_sum_perc_pos / benchmark_question_count) * 100 ).toFixed(0);
            this.benchmarks = [benchmarkData, ...this.benchmarks]
          }
        } catch {
          let benchmarkData = this.data.map(i => i.benchmarks[benchmark.id])
          benchmarkData = {...benchmark}
          this.benchmarks = [benchmarkData, ...this.benchmarks]
        }
      },
      addCompare(compare, compareIndex) {
        if (compare && compare.key && compare.value) {
          // And get the data related to this compare
          this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' + this.content.questions.join(",") + '&compare=' + this.$store.state.availableCompares.findIndex(c => compare.key === c.key && compare.value === c.value))
          .then(resp => { 
            resp.data.forEach(item => {
              item = this.getIndexedCounts(item) // Get the index counts
              item.labels = this.getLabels(item.question.qtype) // Get the labels for each index
            })
  
            let compare_question_count = resp.data.length
            let compare_sum_scores = resp.data.map(i => i.score).reduce((a, b) => a + b, 0)
            let compare_positive_count = resp.data.map(i => i.count_pos).reduce((a, b) => a + b, 0)
            let compare_response_count = resp.data.map(i => i.total_count).reduce((a, b) => a + b, 0)
            let compareData = {...compare}

            compareData.id = this.content.title + '-' + uuidv4()
            compareData.score = (compare_sum_scores / compare_question_count).toFixed(1);
            compareData.percentage = ((compare_positive_count / compare_response_count) * 100 ).toFixed(0);
            compareData.response = Math.round(compare_response_count / compare_question_count);

            this.compares.splice(compareIndex, 1, compareData)
          })
          .catch(err => {
            this.error = err
            this.loading = false;
          })
        }
      },
      removeCompare(compareIndex) {
        this.compares.splice(compareIndex, 1)
      },
      removeBenchmark(benchmarkIndex) {
        this.benchmarks.splice(benchmarkIndex, 1)
      }
    },
    mounted() {
      this.getData()
    },
    watch: {
      'currentFiltersCount': 'getData',
      'currentComparesCount': 'updateCompares',
      'currentBenchmarksCount': 'updateBenchmarks'
    },
  }
</script>

<style>
@media print {
  div.component-single-score div.v-progress-circular {
    width: 100px !important;
    height: 100px !important;
  }
  div.component-single-score div.v-progress-circular__info {
    font-size: 1rem;
    line-height: 1rem;
  }
}

div.component-single-score div.v-progress-circular__info {
    font-size: 3rem;
    line-height: 3rem;
  }
</style>

